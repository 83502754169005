import React, { FC } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import ChipFilter from './ChipFilter';
import { Box, Stack } from '@mui/material';

const HomeFilters: FC = () => {
  const filters = useSelector((state: RootState) => state.filter.filters);
  return (
    <Box sx={{ width: '100vw', overflow: 'scroll' }}>
      <Stack direction="row" spacing={1} sx={{ marginX: '30px', marginY: '10px' }}>
        {filters.map((f) => (
          <ChipFilter key={`filter-${f.id}`} filter={f} />
        ))}
      </Stack>
    </Box>
  );
};

export default HomeFilters;
