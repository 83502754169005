import React, { FC } from 'react';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { bodyActions } from '../../redux/slices/bodySlice';
import ProtocolCard from '../OtherComponents/Card';
import { Box, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './Menu.css';
// https://codepen.io/kylelavery88/pen/WdmVJL
// https://mui.com/material-ui/react-drawer/

const bodyInterface: { [index: string]: any } = {
  head: 'Cuello',
  trapezius: 'Cuello',
  chest: '',
  stomach: '',
  leftShoulder: 'Hombro',
  leftArm: 'Brazo / Codo',
  leftHand: 'Muñeca / Mano',
  leftLeg: 'Pierna / Rodilla',
  leftFoot: 'Pie / Planta del Pie',
  rightShoulder: 'Hombro',
  rightArm: 'Brazo / Codo',
  rightHand: 'Muñeca / Mano',
  rightLeg: 'Pierna / Rodilla',
  rightFoot: 'Pie / Planta del Pie',
  lowerBack: 'Espalda Baja / Glúteo',
  upperBack: 'Espalda Alta',
};

const unsafeProtocols = [5, 9, 12, 18, 19, 20, 21, 22, 23];

const PopUpMenu: FC = () => {
  const menuHidden = useSelector((state: RootState) => state.body.menuHidden);
  const selectedPart = useSelector((state: RootState) => state.body.selectedPart);
  const protocols = useSelector((state: RootState) => state.protocol.protocols);
  let selectedProtocols = protocols.filter((protocol) => protocol.bodyPart === bodyInterface[selectedPart]);
  selectedProtocols = selectedProtocols.filter((protocol) => !unsafeProtocols.includes(+protocol.id));
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(bodyActions.unselect());
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(bodyActions.unselect());
  };

  return (
    <Drawer
      sx={{ zIndex: '10100', borderRadius: '200px' }}
      anchor="bottom"
      open={menuHidden === false}
      onClose={toggleDrawer()}>
      <div className="menu__header">
        <h2 className="App-logo-text">Rutinas - {bodyInterface[selectedPart]}</h2>
        <IconButton onClick={clickHandler} color="primary" aria-label="close-menu" component="label">
          <CloseIcon />
        </IconButton>
      </div>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '10vh',
          gap: 1,
          py: 1,
          overflow: 'auto',
          width: '100vw',
          scrollSnapType: 'x mandatory',
          paddingX: '10vw',
          '& > *': {
            scrollSnapAlign: 'center',
          },
          '::-webkit-scrollbar': { display: 'none' },
        }}>
        {selectedProtocols.map((prot, index) => (
          <ProtocolCard specs={prot} key={`prot${prot.id}-${index}`} />
        ))}
      </Box>
    </Drawer>
  );
};

export default PopUpMenu;
