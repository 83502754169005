import styled from '@emotion/styled';
import { getHex } from './Helpers';

// styled components with @emotion/styled
export const Container = styled.div`
  position: relative;
  min-width: 200px;
  min-height: 120px;
  display: fit-object;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: fit-content;
  height: fit-content;
`;

export const Rect = styled.rect<{
  value: number | any;
}>`
  fill: ${(props) => getHex(+props.value)};
  &:hover {
    stroke-width: 1;
    stroke: rgba(155, 155, 155, 0.05);
  }
`;

export const Label = styled.text`
  font-size: 12px;
  font-weight: bold;
`;

export const Tooltip = styled.div<{
  backgroundColor: string | any;
  top: string | any;
  left: string | any;
}>`
  background-color: ${(props) => props.backgroundColor};
  padding: 8px;
  color: white;
  white-space: nowrap;
  font-weight: semi-bold;
  border-radius: 4px;
  width: fit-content;
  font-size: 10px;
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 1000;
  transform: translate(-50%, 0);
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 0px;
    width: 0;
    height: 0;
    border-top: solid 7px grey;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
    transform: translate(-50%, -3px);
  }
`;
