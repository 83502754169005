import React, { FC, useState } from 'react';
import { Rect, Svg, Container, Label, Tooltip } from './HeatmapStyles';
import { getMonth, getWeeks, shiftDate } from './Helpers';
import { language } from './Constants';

// https://github.com/SabineWren/svg-polyline-rounded-corners/blob/master/round_corner.js

interface DailyValues {
  date: Date;
  value: number;
  tooltip?: string | any;
  [index: string]: any;
}

interface CalendarProps {
  values: DailyValues[];
  lowScaleLable: string;
  upScaleLable: string;
  days: number;
  [index: string]: any;
}

const HeatmapCalendar: FC<CalendarProps> = (props: CalendarProps) => {
  const { days, values } = props;
  const until = new Date();
  const weeks = getWeeks(days, until);
  const LastWeekDays = until.getDay() + 1;
  const FirstWeekDays = (7 + (days % 7) - LastWeekDays) % 7;
  const weekDays = [0, 1, 2, 3, 4, 5, 6];
  const [clicked, setClicked] = useState('');
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const onClickSvg = ({ target }: any) => {
    const id = target.textContent || target.parentElement.textContent || target.parentElement.parentElement.textContent;
    setClicked(id);
    setXPos(target.parentElement.transform.baseVal['0'].matrix.e);
    setYPos(target.y.baseVal.value);
  };
  const onClickSvg2 = () => {
    setClicked('');
  };
  return (
    <Container>
      <Svg className="svg-chart">
        <g transform="translate(0,0)">
          {language['sp'].days.map((d, index) => (
            <Label key={`week-label-${index}`} x="0" y={14 * index + 24}>
              {d}
            </Label>
          ))}
        </g>
        <g transform="translate(28,0)">
          <Label x="0" y="10">
            {`${language['sp'].months[getMonth(-(weeks - 1), until)]}`}
          </Label>
          {weekDays.map(
            (day, index) =>
              index + 1 > 7 - FirstWeekDays && (
                <Rect
                  onMouseEnter={onClickSvg}
                  onMouseLeave={onClickSvg2}
                  key={`week-F0-day-${index}`}
                  value={
                    values.find(
                      (x) =>
                        x.date.toISOString().substring(0, 10) ===
                        shiftDate(until, -(days - index + 6 - FirstWeekDays))
                          .toISOString()
                          .substring(0, 10),
                    )
                      ? values.find(
                          (x) =>
                            x.date.toISOString().substring(0, 10) ===
                            shiftDate(until, -(days - index + 6 - FirstWeekDays))
                              .toISOString()
                              .substring(0, 10),
                        )?.value
                      : 0
                  }
                  width="11"
                  height="11"
                  x="0"
                  y={14 * (day + 1)}
                  data-date={shiftDate(until, -(days - index + 6 - FirstWeekDays)).toDateString()}
                  data-level="0"
                  rx="2"
                  ry="2">
                  {values.find(
                    (x) =>
                      x.date.toISOString().substring(0, 10) ===
                      shiftDate(until, -(days - index + 6 - FirstWeekDays))
                        .toISOString()
                        .substring(0, 10),
                  )?.tooltip
                    ? values.find(
                        (x) =>
                          x.date.toISOString().substring(0, 10) ===
                          shiftDate(until, -(days - index + 6 - FirstWeekDays))
                            .toISOString()
                            .substring(0, 10),
                      )?.tooltip
                    : 'No hay nada'}
                </Rect>
              ),
          )}
        </g>
        {weeks > 2 &&
          [...Array(weeks - 2)].map((e, i) => (
            <g key={`week-${e}-${i}`} transform={`translate(${14 * (i + 3)}, 0)`}>
              <Label x="0" y="10">
                {`${language['sp'].months[getMonth(-(weeks - i - 2), until)]}`}
              </Label>
              {weekDays.map((day, index) => (
                <Rect
                  key={`week-M${e}-day-${index}`}
                  onMouseEnter={onClickSvg}
                  onMouseLeave={onClickSvg2}
                  value={
                    values.find(
                      (x) =>
                        x.date.toISOString().substring(0, 10) ===
                        shiftDate(until, -(days - FirstWeekDays - 7 * i - index - 1))
                          .toISOString()
                          .substring(0, 10),
                    )
                      ? values.find(
                          (x) =>
                            x.date.toISOString().substring(0, 10) ===
                            shiftDate(until, -(days - FirstWeekDays - 7 * i - index - 1))
                              .toISOString()
                              .substring(0, 10),
                        )?.value
                      : 0
                  }
                  width="11"
                  height="11"
                  x="0"
                  y={14 * (day + 1)}
                  data-date={shiftDate(until, -(days - FirstWeekDays - 7 * i - index - 1)).toDateString()}
                  data-level="0"
                  rx="2"
                  ry="2">
                  {values.find(
                    (x) =>
                      x.date.toISOString().substring(0, 10) ===
                      shiftDate(until, -(days - FirstWeekDays - 7 * i - index - 1))
                        .toISOString()
                        .substring(0, 10),
                  )?.date
                    ? values.find(
                        (x) =>
                          x.date.toISOString().substring(0, 10) ===
                          shiftDate(until, -(days - FirstWeekDays - 7 * i - index - 1))
                            .toISOString()
                            .substring(0, 10),
                      )?.tooltip
                    : 'No hay nada'}
                </Rect>
              ))}
            </g>
          ))}
        <g transform={`translate(${14 * (weeks + 1)}, 0)`}>
          {weekDays.map(
            (day, index) =>
              index < LastWeekDays && (
                <Rect
                  onMouseEnter={onClickSvg}
                  onMouseLeave={onClickSvg2}
                  key={`week-L${weeks - 1}-day-${index}`}
                  value={
                    values.find(
                      (x) =>
                        x.date.toISOString().substring(0, 10) ===
                        shiftDate(until, -(LastWeekDays - index - 1))
                          .toISOString()
                          .substring(0, 10),
                    )
                      ? values.find(
                          (x) =>
                            x.date.toISOString().substring(0, 10) ===
                            shiftDate(until, -(LastWeekDays - index - 1))
                              .toISOString()
                              .substring(0, 10),
                        )?.value
                      : 0
                  }
                  width="11"
                  height="11"
                  x="0"
                  y={14 * (day + 1)}
                  data-date={shiftDate(until, -(LastWeekDays - index - 1)).toDateString()}
                  data-level="0"
                  rx="2"
                  ry="2">
                  {values.find(
                    (x) =>
                      x.date.toISOString().substring(0, 10) ===
                      shiftDate(until, -(LastWeekDays - index - 1))
                        .toISOString()
                        .substring(0, 10),
                  )?.tooltip
                    ? values.find(
                        (x) =>
                          x.date.toISOString().substring(0, 10) ===
                          shiftDate(until, -(LastWeekDays - index - 1))
                            .toISOString()
                            .substring(0, 10),
                      )?.tooltip
                    : 'No hay nada'}
                </Rect>
              ),
          )}
        </g>
        <g transform={`translate(${14 * (weeks - 8)}, 105)`}>
          <Label x="-80" y="24">
            {props.lowScaleLable}
          </Label>
          <Rect value="0" width="11" height="11" x="0" y="14" rx="2" ry="2" />
          <Rect value="1" width="11" height="11" x="14" y="14" rx="2" ry="2" />
          <Rect value="2" width="11" height="11" x="28" y="14" rx="2" ry="2" />
          <Rect value="3" width="11" height="11" x="42" y="14" rx="2" ry="2" />
          <Rect value="4" width="11" height="11" x="56" y="14" rx="2" ry="2" />
          <Rect value="5" width="11" height="11" x="70" y="14" rx="2" ry="2" />
          <Label x="84" y="24">
            {props.upScaleLable}
          </Label>
        </g>
      </Svg>
      {clicked !== '' && (
        <Tooltip top={`${yPos - 36}px`} left={`${xPos + 5.5}px`} backgroundColor="grey">
          {clicked}
        </Tooltip>
      )}
    </Container>
  );
};

export default HeatmapCalendar;
