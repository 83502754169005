import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { bodyActions } from '../../redux/slices/bodySlice';
import './BodyMap.css';

// https://i.stack.imgur.com/N6k4x.png
// https://github.com/volcanioo/Human-Body-Rendering-HTML
// https://github.com/mariohmol/reactjs-human-body

const BodyFrontMap: FC = () => {
  const dispatch = useDispatch();

  const onClickSvg = ({ target }: any) => {
    const id = target.id || target.parentElement.id || target.parentElement.parentElement.id;
    dispatch(bodyActions.select(id));
  };

  return (
    <svg width="100%" height="100%" viewBox="0 0 310.000000 760.000000">
      <g transform="translate(0.000000,760.000000) scale(0.100000,-0.100000)">
        <g onClick={onClickSvg} className="sector" id="head">
          <path d="M1486 7509 c-53 -13 -136 -55 -168 -86 -44 -42 -58 -102 -65 -276 -5 -139 -8 -159 -25 -168 -36 -19 -12 -98 46 -152 27 -24 39 -47 52 -97 21 -85 42 -124 101 -188 55 -61 91 -74 179 -65 46 5 61 12 94 44 60 59 90 110 111 194 12 51 33 98 59 137 44 66 49 92 23 121 -15 16 -19 47 -24 181 -5 119 -11 172 -24 204 -22 53 -92 116 -152 136 -59 20 -158 28 -207 15z" />
          <path d="M1370 6502 c0 -37 33 -148 58 -198 28 -54 132 -149 132 -120 0 21 -49 155 -76 206 -36 69 -89 140 -105 140 -5 0 -9 -12 -9 -28z" />
          <path d="M1731 6477 c-50 -66 -118 -208 -127 -267 l-7 -44 29 18 c80 51 111 99 144 222 35 137 25 156 -39 71z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightShoulder">
          <path d="M1175 6411 c-88 -26 -180 -65 -180 -76 0 -20 64 -15 130 10 37 14 70 24 72 22 2 -3 -21 -17 -52 -33 -30 -16 -55 -32 -55 -35 0 -4 15 -13 33 -20 39 -17 101 -16 181 0 l59 13 -7 56 c-4 31 -12 62 -18 69 -15 18 -90 15 -163 -6z m115 5 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z" />
          <path d="M934 6287 c-27 -15 -123 -120 -158 -171 -19 -28 -26 -52 -26 -84 l0 -46 31 49 c17 27 71 91 121 142 49 51 88 99 86 105 -6 18 -29 20 -54 5z" />
          <path d="M937 6172 c-149 -165 -187 -240 -187 -365 l0 -72 31 50 c47 75 66 92 158 143 58 33 92 59 111 87 34 50 90 160 90 178 0 10 -95 77 -110 77 -3 0 -44 -44 -93 -98z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftShoulder">
          <path d="M1825 6428 c-13 -41 -24 -123 -18 -129 15 -14 162 -39 195 -33 40 8 68 22 68 34 0 5 -39 31 -87 58 -143 80 -153 84 -158 70z" />
          <path d="M1994 6385 c107 -54 156 -67 156 -42 0 32 -117 84 -209 92 l-56 5 109 -55z" />
          <path d="M2185 6286 c-18 -13 -18 -15 9 -47 37 -44 216 -229 222 -229 10 0 3 43 -12 73 -35 67 -167 217 -192 217 -5 0 -17 -6 -27 -14z" />
          <path d="M2083 6240 c-38 -22 -53 -37 -53 -51 0 -30 76 -164 114 -202 17 -18 59 -46 91 -64 62 -33 99 -67 154 -143 l33 -45 -5 80 c-3 44 -11 97 -17 117 -16 54 -79 141 -178 245 l-87 93 -52 -30z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="chest">
          <path d="M1161 6088 c-19 -35 -54 -93 -78 -130 -38 -58 -53 -71 -104 -95 -32 -15 -59 -31 -59 -36 0 -6 17 -29 39 -52 24 -27 49 -70 67 -119 16 -42 38 -88 49 -102 11 -13 53 -38 94 -54 l73 -30 100 53 c54 28 113 64 130 78 30 24 31 28 40 140 5 63 6 163 2 223 -6 101 -9 112 -33 137 -28 27 -21 26 -221 45 l-65 6 -34 -64z" />
          <path d="M1798 6139 c-116 -10 -142 -16 -164 -41 -17 -18 -19 -40 -19 -249 0 -266 -18 -229 155 -318 l115 -59 65 25 c75 28 101 50 123 101 57 135 73 165 99 187 15 13 28 30 28 38 0 8 -24 26 -54 40 -61 29 -97 69 -150 165 -68 123 -67 123 -198 111z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightArm">
          <path d="M835 5729 c-47 -56 -74 -125 -99 -251 -35 -173 -68 -407 -64 -458 l3 -42 116 114 c117 115 117 115 133 185 8 38 24 133 35 209 l20 139 -31 45 c-38 54 -69 90 -79 90 -4 0 -19 -14 -34 -31z" />
          <path d="M653 5553 c-25 -54 -26 -61 -20 -196 3 -76 9 -143 14 -148 6 -6 14 22 22 73 15 101 27 328 17 328 -4 0 -19 -25 -33 -57z" />
          <path d="M792 5017 c-50 -51 -96 -107 -102 -123 -11 -29 -6 -94 8 -94 23 1 143 134 173 192 26 51 40 118 25 118 -7 0 -53 -42 -104 -93z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftArm">
          <path d="M2229 5733 c-14 -16 -38 -45 -53 -66 l-28 -39 22 -176 c12 -98 25 -191 28 -207 5 -22 41 -63 120 -138 61 -59 117 -107 122 -107 23 0 -2 251 -36 373 -8 29 -19 90 -25 137 -12 95 -44 172 -93 219 l-32 31 -25 -27z" />
          <path d="M2426 5598 c2 -13 11 -102 19 -199 8 -97 18 -185 22 -195 13 -35 21 24 22 170 1 122 -2 145 -20 182 -25 53 -48 75 -43 42z" />
          <path d="M2220 5093 c0 -48 45 -127 120 -212 76 -86 100 -99 100 -53 -2 70 -60 157 -167 248 l-53 46 0 -29z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="stomach">
          <path d="M1421 5444 c-40 -13 -87 -32 -103 -40 l-30 -16 6 -71 c10 -100 13 -109 39 -101 12 3 61 26 109 51 79 40 89 49 98 81 19 70 9 122 -24 122 -11 0 -54 -12 -95 -26z" />
          <path d="M1594 5446 c-3 -14 -3 -46 0 -71 6 -35 15 -50 39 -69 33 -25 197 -110 202 -104 1 2 5 42 9 91 l6 87 -32 20 c-44 27 -135 61 -180 67 -34 5 -38 3 -44 -21z" />
          <path d="M1049 5370 c17 -46 33 -85 35 -87 13 -13 116 69 116 93 0 14 -103 64 -148 71 l-33 6 30 -83z" />
          <path d="M2017 5425 c-33 -13 -65 -29 -73 -36 -17 -14 -11 -23 56 -79 l48 -40 31 78 c17 42 31 83 31 90 0 17 -26 14 -93 -13z" />
          <path d="M1170 5285 c-38 -39 -38 -38 22 -70 39 -22 41 -20 36 36 -7 70 -18 76 -58 34z" />
          <path d="M1916 5288 c-7 -29 -8 -88 -1 -88 13 0 85 41 85 48 0 12 -54 62 -67 62 -6 0 -13 -10 -17 -22z" />
          <path d="M1100 5183 c1 -61 31 -115 85 -151 72 -47 71 -48 54 44 -11 62 -40 103 -91 130 -41 21 -48 18 -48 -23z" />
          <path d="M1616 5205 c-3 -9 -6 -45 -6 -80 l0 -63 69 -16 c119 -27 131 -22 131 50 0 41 -22 65 -87 94 -70 31 -99 35 -107 15z" />
          <path d="M1967 5191 c-36 -23 -48 -38 -56 -68 -17 -67 -23 -123 -14 -123 5 0 31 14 58 32 54 36 84 90 85 151 0 46 -11 47 -73 8z" />
          <path d="M1450 5201 c-47 -15 -108 -50 -114 -66 -3 -9 -6 -39 -6 -67 0 -42 3 -49 16 -44 10 4 50 9 90 13 41 3 79 12 84 19 6 7 10 43 8 81 -3 65 -4 68 -28 70 -14 1 -36 -2 -50 -6z" />
          <path d="M1130 4965 c0 -31 6 -68 14 -84 13 -25 82 -81 99 -81 11 0 8 119 -3 140 -9 18 -91 80 -104 80 -3 0 -6 -25 -6 -55z" />
          <path d="M1949 4986 c-26 -19 -49 -42 -53 -51 -9 -24 -7 -135 2 -135 4 0 26 13 49 30 53 39 67 74 56 140 l-8 52 -46 -36z" />
          <path d="M1427 4970 c-67 -8 -77 -23 -80 -109 l-2 -73 80 -24 c44 -13 85 -22 92 -19 14 5 18 136 7 193 -7 35 -24 40 -97 32z" />
          <path d="M1633 4969 c-22 -8 -23 -14 -23 -113 0 -58 4 -107 9 -110 5 -4 46 4 90 16 l81 21 0 73 c0 107 4 102 -110 118 -14 2 -35 0 -47 -5z" />
          <path d="M1112 4763 c-5 -10 -21 -60 -36 -111 -14 -51 -37 -115 -51 -142 -14 -27 -25 -54 -25 -61 0 -20 110 -167 152 -204 46 -38 74 -45 82 -17 12 39 26 204 26 315 l0 117 -39 43 c-22 23 -53 50 -70 60 -29 17 -30 17 -39 0z" />
          <path d="M1941 4724 c-70 -63 -75 -87 -61 -277 15 -200 22 -237 48 -237 29 0 88 56 152 144 33 46 60 88 60 93 0 4 -18 44 -40 87 -21 44 -44 104 -51 135 -15 75 -26 101 -43 101 -7 0 -37 -21 -65 -46z" />
          <path d="M1326 4711 c-3 -5 -10 -131 -16 -281 l-10 -272 86 -106 c47 -59 98 -129 114 -155 l28 -48 7 47 c9 64 -3 571 -16 656 -9 59 -15 72 -47 99 -35 31 -105 68 -129 69 -6 0 -14 -4 -17 -9z" />
          <path d="M1735 4694 c-75 -38 -106 -73 -116 -132 -4 -28 -11 -195 -15 -371 -5 -243 -4 -321 5 -321 6 0 26 21 44 47 17 25 66 91 109 145 l76 100 -5 161 c-7 243 -18 366 -34 382 -12 12 -21 10 -64 -11z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightHand">
          <path d="M575 4886 c-119 -270 -260 -886 -202 -886 23 0 238 573 257 685 9 57 -5 234 -20 249 -6 6 -18 -10 -35 -48z" />
          <path d="M775 4783 c-70 -83 -110 -167 -213 -443 -103 -278 -135 -377 -128 -395 9 -24 52 -18 75 10 21 28 170 327 232 468 22 48 46 116 54 150 27 108 53 267 45 272 -5 3 -34 -25 -65 -62z" />
          <path d="M260 3991 c-116 -48 -266 -159 -259 -193 4 -16 10 -15 74 7 79 28 95 30 95 13 0 -7 -34 -84 -76 -170 -42 -86 -73 -161 -69 -167 12 -20 42 7 97 84 50 71 78 92 78 61 0 -8 -13 -48 -30 -89 -30 -74 -36 -108 -24 -120 16 -16 48 21 89 103 46 92 60 109 77 92 6 -6 1 -37 -16 -91 -31 -98 -31 -101 -7 -101 15 0 28 18 51 68 43 92 56 116 71 121 12 4 10 -48 -7 -141 -5 -30 -3 -38 8 -38 43 0 96 249 82 387 l-7 62 -61 18 c-56 17 -63 22 -86 66 -14 26 -27 47 -30 46 -3 0 -25 -8 -50 -18z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftHand">
          <path d="M2496 4952 c-4 -7 -14 -63 -22 -125 l-15 -114 85 -229 c68 -186 128 -341 173 -451 3 -7 12 -13 20 -13 19 0 15 49 -23 275 -32 196 -60 300 -128 472 -65 169 -81 200 -90 185z" />
          <path d="M2264 4848 c3 -13 12 -70 21 -127 9 -58 28 -141 42 -185 30 -90 262 -564 285 -584 19 -15 68 -6 68 12 0 35 -196 576 -251 692 -25 52 -149 214 -164 214 -3 0 -3 -10 -1 -22z" />
          <path d="M2771 3965 c-23 -43 -62 -67 -126 -77 -31 -5 -40 -66 -26 -190 6 -59 21 -138 32 -175 34 -110 71 -130 50 -27 -14 72 -14 114 0 114 5 0 30 -42 54 -94 40 -87 66 -118 80 -96 3 5 -6 48 -21 95 -19 60 -24 89 -16 97 17 17 30 1 76 -91 46 -90 68 -117 86 -106 16 10 12 38 -20 125 -34 92 -35 100 -16 100 7 0 35 -32 61 -71 27 -38 57 -76 68 -84 51 -36 38 10 -57 204 -76 154 -76 155 21 121 71 -25 83 -25 83 -1 0 28 -101 105 -205 156 -50 25 -93 45 -95 45 -3 0 -16 -20 -29 -45z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightLeg">
          <path d="M961 4307 c-5 -13 -14 -59 -21 -103 -6 -43 -21 -131 -32 -194 -18 -101 -20 -141 -15 -325 3 -115 10 -221 15 -235 l10 -25 10 25 c15 38 70 377 83 510 15 156 9 347 -11 360 -23 15 -30 12 -39 -13z" />
          <path d="M1087 4178 c-4 -29 -7 -97 -7 -150 l0 -97 108 -218 c110 -223 161 -344 232 -550 22 -64 43 -119 46 -122 7 -8 -6 187 -18 249 -5 30 -30 111 -55 180 -43 120 -291 751 -297 757 -2 2 -6 -20 -9 -49z" />
          <path d="M1200 4107 c0 -2 30 -80 66 -173 36 -93 70 -181 76 -196 5 -16 13 -28 16 -28 4 0 29 25 55 56 39 43 48 60 43 79 -9 37 -256 289 -256 262z" />
          <path d="M1047 3863 c-36 -61 -45 -565 -14 -761 26 -163 141 -542 181 -598 16 -22 21 -4 73 296 l35 205 -17 145 c-20 175 -38 262 -75 356 -35 87 -167 364 -174 364 -3 0 -7 -3 -9 -7z" />
          <path d="M1427 3701 l-52 -49 14 -39 c41 -112 84 -204 91 -192 11 20 29 257 23 297 -3 17 -10 32 -15 32 -5 0 -33 -22 -61 -49z" />
          <path d="M950 3254 c0 -5 -7 -70 -15 -144 l-14 -135 91 -320 c50 -176 95 -324 99 -329 9 -10 60 94 61 124 1 8 -30 101 -69 205 -77 206 -104 308 -128 485 -14 99 -25 146 -25 114z" />
          <path d="M1354 3115 c19 -152 18 -178 -23 -434 -23 -139 -41 -260 -41 -267 0 -16 57 -54 81 -54 13 0 19 25 33 143 46 393 44 447 -21 581 -31 67 -33 69 -29 31z" />
          <path d="M1040 2253 c0 -49 -2 -146 -5 -214 -5 -122 -5 -123 12 -91 9 18 27 35 40 38 13 4 23 13 23 20 0 21 -61 328 -66 333 -2 2 -4 -36 -4 -86z" />
          <path d="M1225 2275 c-5 -2 -22 -6 -36 -9 -31 -7 -59 -57 -59 -107 0 -18 15 -62 34 -98 18 -36 37 -85 41 -108 8 -50 27 -78 47 -71 15 6 88 281 88 332 0 16 -9 31 -22 40 -24 14 -75 26 -93 21z" />
          <path d="M1431 2145 c-8 -22 -41 -98 -74 -168 -58 -125 -59 -131 -55 -196 9 -121 18 -116 82 47 52 129 60 157 64 232 6 112 0 138 -17 85z" />
          <path d="M1065 1879 c-13 -56 -56 -360 -62 -434 -8 -108 12 -198 97 -455 124 -373 167 -480 195 -480 22 0 19 47 -15 245 -34 199 -50 398 -50 634 l0 163 -71 167 c-40 91 -75 170 -80 175 -4 5 -10 -2 -14 -15z" />
          <path d="M1376 1699 c-3 -8 -10 -41 -15 -74 -53 -310 -58 -643 -14 -905 l18 -105 6 60 c4 33 7 123 8 200 1 123 5 155 33 265 29 112 33 140 33 265 0 104 -5 159 -18 212 -19 72 -42 109 -51 82z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftLeg">
          <path d="M2120 4165 c0 -170 10 -291 36 -450 27 -165 56 -316 61 -312 9 9 43 388 43 480 0 75 -7 125 -29 215 -16 64 -32 134 -36 155 -6 40 -35 77 -60 77 -13 0 -15 -25 -15 -165z" />
          <path d="M2023 4200 c-12 -30 -94 -242 -183 -470 l-161 -415 -5 -140 -5 -140 59 165 c65 183 108 287 211 507 101 215 112 260 109 418 l-3 130 -22 -55z" />
          <path d="M1869 4062 c-95 -97 -177 -190 -189 -211 -7 -15 -2 -28 26 -65 19 -25 43 -50 54 -56 16 -9 22 -5 36 21 21 41 145 371 141 375 -2 2 -32 -27 -68 -64z" />
          <path d="M2042 3804 c-62 -125 -149 -324 -166 -385 -9 -31 -27 -130 -41 -220 l-24 -164 46 -278 c26 -152 50 -274 54 -270 8 9 31 68 125 322 l64 174 10 166 c18 309 9 669 -20 720 -6 11 -19 -8 -48 -65z" />
          <path d="M1636 3753 c-15 -15 8 -343 25 -343 3 0 27 55 53 122 l47 121 -43 44 c-47 49 -72 66 -82 56z" />
          <path d="M2167 3245 c-4 -22 -7 -78 -7 -124 0 -104 -14 -157 -120 -446 l-80 -219 26 -69 c15 -38 30 -65 35 -60 8 8 86 263 142 463 29 107 32 125 31 265 -1 83 -5 168 -11 190 l-9 40 -7 -40z" />
          <path d="M1761 3118 c-5 -13 -21 -52 -36 -88 -24 -54 -28 -78 -27 -142 2 -127 47 -515 62 -531 4 -5 24 6 44 23 45 40 45 38 4 277 -25 151 -31 217 -32 336 0 81 -2 147 -3 147 -2 0 -7 -10 -12 -22z" />
          <path d="M2088 2330 c-11 -23 -58 -271 -58 -307 0 -26 4 -33 19 -33 11 0 23 -9 26 -21 17 -54 25 11 23 187 -2 104 -6 182 -10 174z" />
          <path d="M1870 2264 c-53 -45 -64 -77 -50 -148 31 -159 52 -236 66 -236 17 0 44 51 44 83 0 13 18 59 40 102 65 127 52 196 -42 219 -20 4 -34 0 -58 -20z" />
          <path d="M1687 2156 c-15 -38 -6 -132 17 -196 66 -176 103 -265 112 -263 5 1 13 21 17 44 13 62 -5 136 -62 258 -27 58 -55 122 -63 141 -12 28 -15 31 -21 16z" />
          <path d="M1977 1715 l-77 -184 0 -158 c0 -310 -15 -471 -59 -646 -22 -86 -38 -164 -35 -172 8 -21 43 -29 61 -13 37 30 132 270 183 461 46 172 63 296 62 452 0 169 -32 445 -51 445 -4 0 -42 -83 -84 -185z" />
          <path d="M1729 1684 c-45 -119 -50 -360 -9 -519 19 -73 29 -169 47 -440 l7 -100 18 105 c25 140 35 496 19 651 -13 128 -48 309 -61 323 -6 6 -14 -2 -21 -20z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightFoot">
          <path d="M1290 432 c-85 -41 -115 -73 -193 -208 -47 -81 -59 -132 -34 -152 22 -18 117 -32 216 -32 79 0 101 3 112 16 19 24 30 318 13 360 -22 54 -32 55 -114 16z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftFoot">
          <path d="M1757 417 c-12 -35 -13 -75 -8 -204 9 -202 14 -213 101 -213 39 0 82 9 126 25 36 14 75 25 84 25 27 0 50 27 50 59 0 29 -89 187 -139 248 -22 27 -53 46 -113 70 -45 18 -83 33 -84 33 -1 0 -9 -19 -17 -43z" />
        </g>
      </g>
    </svg>
  );
};

export default BodyFrontMap;
