import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import { Box, Fade } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TableHead } from '@mui/material';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { bodyPartDetail } from '../../../redux/types/nordicFormTypes';

enum TotalTime {
  Zero = '0 dias',
  Week = '1 a 7 dias',
  Month = '8 a 30 dias',
  More = 'Más de 30 dias',
  All = 'Todos los dias',
}

interface StateProps {
  name: string;
  tag: string;
  onChange: (e: boolean) => void;
}

interface DispatchProps {
  updatePartDetailsInteractor: typeof NordicFormInteractors.updatePartDetailsInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const NordicPartDetail: FC<Props> = (props: Props) => {
  const { tag } = props;
  const [hospitalized, setHospitalized] = useState<boolean | null>(null);
  const [changeJobs, setChangeJobs] = useState<boolean | null>(null);
  const [totalTime, setTotalTime] = useState<TotalTime | string | null>(null);
  const [trauma, setTrauma] = useState<boolean | null>(null);
  const [swelling, setSwelling] = useState<boolean | null>(null);
  const [workActivity, setWorkActivity] = useState<boolean | null>(null);
  const [leisureActivity, setLeisureActivity] = useState<boolean | null>(null);
  const [preventedTime, setPreventedTime] = useState<TotalTime | string | null>(null);
  const [medicalCare, setMedicalCare] = useState<boolean | null>(null);
  const [weekProblems, setWeekProblems] = useState<boolean | null>(null);

  const updateStore = (name: string, value: any) => {
    const partDetails: bodyPartDetail = {
      hospitalized,
      changeJobs,
      totalTime,
      workActivity,
      leisureActivity,
      preventedTime,
      medicalCare,
      weekProblems,
      trauma,
      swelling,
    };
    partDetails[name] = value;
    props.updatePartDetailsInteractor({ name: props.name, value: { ...partDetails } });
  };
  useEffect(() => {
    let next = true;
    if (medicalCare === null || hospitalized === null || changeJobs === null || totalTime === null) {
      next = false;
    }
    if (totalTime !== null && totalTime !== '' && totalTime !== '0 dias') {
      if (workActivity === null || leisureActivity === null || preventedTime === null || weekProblems === null) {
        next = false;
      }
    }
    props.onChange(next);
  }, [medicalCare, hospitalized, changeJobs, totalTime, workActivity, leisureActivity, preventedTime, weekProblems]);

  useEffect(() => {
    props.onChange(true);
  }, []);

  const handleHospitalized = (event: ChangeEvent<HTMLInputElement>) => {
    setHospitalized(event.target.value === 'true');
    updateStore('hospitalized', event.target.value === 'true');
  };

  const handleChangeJobs = (event: ChangeEvent<HTMLInputElement>) => {
    setChangeJobs(event.target.value === 'true');
    updateStore('changeJobs', event.target.value === 'true');
  };

  const handleTotalTime = (event: ChangeEvent<HTMLInputElement>) => {
    setTotalTime(event.target.value);
    updateStore('totalTime', event.target.value);
  };

  const handleTrauma = (event: ChangeEvent<HTMLInputElement>) => {
    setTrauma(event.target.value === 'true');
    updateStore('trauma', event.target.value === 'true');
  };

  const handleSwelling = (event: ChangeEvent<HTMLInputElement>) => {
    setSwelling(event.target.value === 'true');
    updateStore('swelling', event.target.value === 'true');
  };

  const handleWorkActivity = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkActivity(event.target.value === 'true');
    updateStore('workActivity', event.target.value === 'true');
  };

  const handleLeisureActivity = (event: ChangeEvent<HTMLInputElement>) => {
    setLeisureActivity(event.target.value === 'true');
    updateStore('leisureActivity', event.target.value === 'true');
  };

  const handlePreventedTime = (event: ChangeEvent<HTMLInputElement>) => {
    setPreventedTime(event.target.value);
    updateStore('preventedTime', event.target.value);
  };

  const handleMedicalCare = (event: ChangeEvent<HTMLInputElement>) => {
    setMedicalCare(event.target.value === 'true');
    updateStore('medicalCare', event.target.value === 'true');
  };

  const handleWeekProblems = (event: ChangeEvent<HTMLInputElement>) => {
    setWeekProblems(event.target.value === 'true');
    updateStore('weekProblems', event.target.value === 'true');
  };

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '20px',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '500px',
          maxWidth: '92vw',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
        }}>
        <FormControl>
          <FormLabel sx={{ color: '#1b4ed8 !important' }}>
            ¿Has sido <b>atendido por un médico, fisioterapeuta, u otra persona</b> por problemas en <b>{tag}</b> en los{' '}
            <b>últimos 12 meses</b>?
          </FormLabel>
          <RadioGroup
            value={medicalCare}
            onChange={handleMedicalCare}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel value={true} control={<Radio />} label="Si" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
          <FormLabel sx={{ color: '#1b4ed8 !important' }}>
            ¿Has sido <b>hospitalizado</b> por problemas en {tag}?
          </FormLabel>
          <RadioGroup
            value={hospitalized}
            onChange={handleHospitalized}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel value={true} control={<Radio />} label="Si" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
          <FormLabel sx={{ color: '#1b4ed8 !important' }}>
            ¿Alguna vez has tenido que <b>cambiar de trabajo</b> o deberes debido a problemas en {tag}?
          </FormLabel>
          <RadioGroup
            value={changeJobs}
            onChange={handleChangeJobs}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel value={true} control={<Radio />} label="Si" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
          <FormLabel sx={{ color: '#1b4ed8 !important' }}>
            ¿Cúal es el tiempo total que has tenido problemas en <b>{tag}</b> durante los <b>últimos 12 meses</b>?
          </FormLabel>
          <RadioGroup
            value={totalTime}
            onChange={handleTotalTime}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel value={TotalTime.Zero} control={<Radio />} label={TotalTime.Zero} />
            <FormControlLabel value={TotalTime.Week} control={<Radio />} label={TotalTime.Week} />
            <FormControlLabel value={TotalTime.Month} control={<Radio />} label={TotalTime.Month} />
            <FormControlLabel value={TotalTime.More} control={<Radio />} label={TotalTime.More} />
            <FormControlLabel value={TotalTime.All} control={<Radio />} label={TotalTime.All} />
          </RadioGroup>
          {(totalTime === '0 dias' || totalTime === '1 a 7 dias') && (
            <>
              <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                ¿Tu molestia es producto de un <b>golpe, caída o torcedura</b> reciente?
              </FormLabel>
              <RadioGroup
                value={trauma}
                onChange={handleTrauma}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group">
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
              <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                ¿Presentas <b>hinchazón o inflamación</b> en la zona?
              </FormLabel>
              <RadioGroup
                value={swelling}
                onChange={handleSwelling}
                aria-labelledby="demo-radio-buttons-group-labfel"
                name="radio-buttons-group">
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </>
          )}
          <Box hidden={totalTime === '0 dias' || totalTime === '' || totalTime === null}>
            <Fade in={totalTime !== '0 dias' && totalTime !== '' && totalTime !== null}>
              <Box>
                <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                  ¿Los problemas de {tag} te han hecho <b>reducir su actividad</b> durante los <b>últimos 12 meses</b>?
                </FormLabel>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell>Si</TableCell>
                      <TableCell>No</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ marginY: '0px', paddingY: '0px' }}>
                      <TableCell sx={{ marginY: '0px', paddingY: '0px' }}>
                        Actividad <b>Laboral</b>
                      </TableCell>
                      <TableCell sx={{ margin: '0px', padding: '0px' }}>
                        <Radio
                          checked={workActivity === true}
                          onChange={handleWorkActivity}
                          value={true}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': `${true}` }}
                          sx={{
                            marginX: '0px',
                            paddingX: '0px',
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ margin: '0px', padding: '0px' }}>
                        <Radio
                          checked={workActivity === false}
                          onChange={handleWorkActivity}
                          value={false}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': `${false}` }}
                          sx={{
                            marginX: '0px',
                            paddingX: '0px',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ marginY: '0px', paddingY: '0px' }}>
                      <TableCell sx={{ marginY: '0px', paddingY: '0px' }}>
                        Actividad de <b>Ocio</b>
                      </TableCell>
                      <TableCell sx={{ margin: '0px', padding: '0px' }}>
                        <Radio
                          checked={leisureActivity === true}
                          onChange={handleLeisureActivity}
                          value={true}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': `${true}` }}
                          sx={{
                            marginX: '0px',
                            paddingX: '0px',
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ margin: '0px', padding: '0px' }}>
                        <Radio
                          checked={leisureActivity === false}
                          onChange={handleLeisureActivity}
                          value={false}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': `${false}` }}
                          sx={{
                            marginX: '0px',
                            paddingX: '0px',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                  ¿Cúal es el tiempo que los problemas de <b>{tag}</b> te han impedido <b>hacer tu trabajo</b> de manera
                  normal (en casa o fuera de casa) durante los <b>últimos 12 meses</b>?
                </FormLabel>
                <RadioGroup
                  value={preventedTime}
                  onChange={handlePreventedTime}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group">
                  <FormControlLabel value={TotalTime.Zero} control={<Radio />} label={TotalTime.Zero} />
                  <FormControlLabel value={TotalTime.Week} control={<Radio />} label={TotalTime.Week} />
                  <FormControlLabel value={TotalTime.Month} control={<Radio />} label={TotalTime.Month} />
                  <FormControlLabel value={TotalTime.More} control={<Radio />} label={TotalTime.More} />
                  <FormControlLabel value={TotalTime.All} control={<Radio />} label={TotalTime.All} />
                </RadioGroup>
                <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                  ¿Has tenido <b>problemas</b> en <b>{tag}</b> en algún momento durante los <b>últimos 7 días</b>?
                </FormLabel>
                <RadioGroup
                  value={weekProblems}
                  onChange={handleWeekProblems}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group">
                  <FormControlLabel value={true} control={<Radio />} label="Si" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </Box>
            </Fade>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicPartDetail);
