import React, { FC } from 'react';
import { TableHead } from '@mui/material';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import SofiTableRow2 from './SofiTableRow2';

interface Part {
  tag: string;
  name: string;
}

interface Props {
  name: string;
  valueRange: number[];
  columnVars: Part[];
}

const SofiFormTable2: FC<Props> = (props: Props) => {
  const { name, valueRange, columnVars } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell> </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {columnVars.map((c) => (
          <SofiTableRow2 key={`${name}-body-${c.name}`} tag={c.tag} name={c.name} valueRange={valueRange} />
        ))}
      </TableBody>
    </Table>
  );
};

export default SofiFormTable2;
