import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Box } from '@mui/material';
import './WellnessHeatmap.css';
import HeatmapCalendar from '../EABCharts/HeatmapCalendar/HeatmapCalendar';

const WellnessHeatmap: FC = () => {
  const { registerOuts } = useSelector((state: RootState) => state.user);
  const regs = registerOuts ? registerOuts : [];
  const testValues = regs.map((reg) => {
    const date = new Date(reg.created_at.substring(0, 10));
    return {
      date,
      value: +reg.fatigue,
      tooltip: `Registro de fatiga de ${+reg.fatigue}, en ${date.toISOString().substring(0, 10)}`,
    };
  });
  return (
    <Box sx={{ marginX: '40px', maxWidth: '442px', width: '80vw', marginTop: '10px', marginBottom: '30px' }}>
      <Box sx={{ marginY: '0', paddingY: '0' }}>
        <h2 className="App-logo-text">Calendario de Bienestar</h2>
      </Box>
      <HeatmapCalendar values={testValues} days={120} lowScaleLable="Menos fatiga" upScaleLable="Más fatiga" />
    </Box>
  );
};

export default WellnessHeatmap;
