import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { Category } from '../redux/types/categoryTypes';

const getCategories = async (): Promise<any> => {
  const response: AxiosResponse<Category[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'get',
    url: `/api/categories`,
  });
  return response?.data;
};

const categoryService = {
  getCategories,
};

export default categoryService;
