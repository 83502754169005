import React, { FC, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import userService from '../../services/UserServices';
import { Box, FormControl, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import { getRecomendation } from '../Recommender/tree';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <h1 className="npnm">😩</h1>,
    label: '5',
  },
  2: {
    icon: <h1 className="npnm">😞</h1>,
    label: '4',
  },
  3: {
    icon: <h1 className="npnm">😐</h1>,
    label: '3',
  },
  4: {
    icon: <h1 className="npnm">🙂</h1>,
    label: '2',
  },
  5: {
    icon: <h1 className="npnm">😄</h1>,
    label: '1',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const setBodyPart = (url: string) => {
  switch (url[1]) {
    case '1':
      return 'el cuello';
    case '2':
      return 'los hombros';
    case '3':
      return 'el brazo/codo';
    case '4':
      return 'la muñeca/mano';
    case '5':
      return 'la espalda alta';
    case '6':
      return 'la espalda baja';
    case '7':
      return 'las caderas/piernas';
    case '8':
      return 'las rodillas';
    case '9':
      return 'los tobillos/pies';
    default:
      return '';
  }
};

interface Props {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
}

const RegisterInForm: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan } = useParams();
  const bodyPart = setBodyPart(plan ? plan : '');
  const user_id = useSelector((state: RootState) => state.user.id);
  const [fatigue, setFatigue] = useState(NaN);
  const [musclePain, setMusclePain] = useState(NaN);
  const [feeling, setFeeling] = useState(NaN);
  const [protocolId, setProtocolId] = useState(NaN);

  const register = () => {
    userService.registerInUser(6 - fatigue, 6 - musclePain, 6 - feeling, user_id);
    dispatch(userActions.setMode({ mode: 'active-out' }));
    if (plan !== 'no') {
      props.selectProtocolInteractor(protocolId);
    }
    navigate('/routine');
  };

  const handlePain = (event: any, value: any) => {
    setMusclePain(value);
    let newI = 6 - value;
    if (newI <= 2) {
      newI = 2;
    } else if (newI <= 4) {
      newI = 3;
    } else {
      newI = 4;
    }
    if (plan !== 'no') {
      const recomendation = getRecomendation(`${plan?.substring(0, 6)}i${newI}${plan?.substring(8)}`);
      setProtocolId(recomendation.id);
    }
  };

  return (
    <FormControl>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Qué tan <b>CANSADO</b> te sientes?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={fatigue}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(event: any, newValue: any) => {
            setFatigue(newValue);
          }}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Cansado</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Descansado</Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Cuanta <b>MOLESTIA FISICA</b> tienes{bodyPart !== '' ? ` en ${bodyPart}` : ''}?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          value={musclePain}
          onChange={handlePain}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Mucha Molestia</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Sin Molestia</Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Cómo te sientes <b>AHORA</b>?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={feeling}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(event: any, newValue: any) => {
            setFeeling(newValue);
          }}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Muy Mal</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Muy Bien</Typography>
        </Stack>
      </Box>
      {fatigue !== null &&
        musclePain !== null &&
        feeling !== null &&
        isNaN(fatigue) === false &&
        isNaN(musclePain) === false &&
        isNaN(feeling) === false && (
          <Box sx={{ margin: '20px' }}>
            <Button
              onClick={register}
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%' }}>
              EMPECEMOS
            </Button>
          </Box>
        )}
    </FormControl>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(RegisterInForm);
