import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import userService from '../../services/UserServices';
import { Box, Fade, Link } from '@mui/material';
import PreviewCard from './PreviewCard';

interface Props {
  url: string;
}

const setCTA = (url: string) => {
  switch (url[1]) {
    case '1':
      return 'Alivia tu Cuello';
    case '2':
      return 'Relaja tus Hombros';
    case '3':
      return 'Repara y regenera tu Brazo / Codo';
    case '4':
      return 'Alivia tu Muñeca / Mano';
    case '5':
      return 'Dale a tu Espalda Alta un respiro';
    case '6':
      return 'Descansa y restaura tu Espalda Baja';
    case '7':
      return 'Trabaja tus Caderas / Piernas';
    case '8':
      return 'Rejuvenece tus Rodillas';
    case '9':
      return 'Descanza tus Tobillos / Pies';
    default:
      return 'No hay preferencia';
  }
};

const setCover = () => {
  const covers = ['248', '040', '313', '422', '514']; // 461, 176, 243
  const index = Math.floor(Math.random() * covers.length);
  return `https://cdn.makana.cl/mkn-${covers[index]}.jpg`;
};
// https://cdn.makana.cl/mkn-248.jpg
//

const setImgUrl = (url: string) => {
  switch (url[1]) {
    case '1':
      return 'https://cdn.makana.cl/mkn-526.jpg';
    case '2':
      return 'https://cdn.makana.cl/mkn-026.jpg';
    case '3':
      return 'https://cdn.makana.cl/mkn-419.jpg';
    case '4':
      return 'https://cdn.makana.cl/mkn-327.jpg';
    case '5':
      return 'https://cdn.makana.cl/mkn-247.jpg';
    case '6':
      return 'https://cdn.makana.cl/mkn-057.jpg';
    case '7':
      return 'https://cdn.makana.cl/mkn-512.jpg';
    case '8':
      return 'https://cdn.makana.cl/mkn-075.jpg';
    case '9':
      return 'https://cdn.makana.cl/mkn-572.jpg';
    default:
      return '';
  }
};

const percentageToEmoji = (percentage: number) => {
  let emoji = '';
  if (percentage < 25) {
    emoji = '😭';
  } else if (percentage < 50) {
    emoji = '👀';
  } else if (percentage < 75) {
    emoji = '💪💪';
  } else if (percentage < 100) {
    emoji = '🔥🔥🔥';
  } else {
    emoji = '🏁🏁🏁';
  }
  return emoji;
};

const setCountPhrase = (count: number, plan: string) => {
  let sessions = 0;
  if (plan === 'c3') {
    sessions = 4;
  } else if (plan === 'c2') {
    sessions = 3;
  } else if (plan === 'c1') {
    sessions = 2;
  } else {
    sessions = 1;
  }
  const percentage = Math.floor((count / sessions) * 100);
  return `Llevas ${count} / ${sessions} sesiones\nesta semana!${percentageToEmoji(percentage)}`;
};

const Cta0: FC = () => {
  return (
    <Link
      sx={{
        color: 'white',
        backgroundImage: 'linear-gradient(to bottom right,#0068ff, #5bab92)',
        minWidth: '60vw',
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '1.5vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
      }}
      href={`/nordic`}>
      Empecemos!
    </Link>
  );
};
const Cta1: FC<Props> = (props: Props) => {
  return (
    <Link
      sx={{
        color: 'white',
        backgroundImage: 'linear-gradient(to bottom right,#0068ff, #5bab92)',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '1.5vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }}
      href={`/in/${props.url}`}>
      Empecemos!
    </Link>
  );
};
const Cta2: FC<Props> = (props: Props) => {
  return (
    <Link
      sx={{
        color: 'primary.main',
        backgroundColor: 'white',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }}
      href={`/in/${props.url}`}>
      Vamos allá!
    </Link>
  );
};

const Cta3: FC<Props> = (props: Props) => {
  return (
    <Link
      sx={{
        color: 'white',
        backgroundColor: 'primary.main',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }}
      href={`/in/${props.url}`}>
      Hagámoslo!
    </Link>
  );
};

const Cta4: FC = () => {
  return (
    <Link
      sx={{
        color: 'white',
        minWidth: '60vw',
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }}
      href={`/update_plan`}>
      Modificar mi Plan &gt;
    </Link>
  );
};

const PlanCarousel: FC = () => {
  const token = useSelector((state: RootState) => state.user.token);
  const [myPlan, setMyPlan] = useState('');
  const [count, setCount] = useState(0);
  const [risk, setRisk] = useState('');
  const [priority1, setPriority1] = useState('');
  const [priority2, setPriority2] = useState('');
  const [hasPlan, setHasPlan] = useState(false);
  const plan = async (token: string) => {
    const response = await userService.getUserPlan(token);
    if (response.plan) {
      setHasPlan(true);
      setMyPlan(response.plan[response.today]);
      setPriority1(response.plan.priority_1);
      setPriority2(response.plan.priority_2);
      setCount(response.count);
      setRisk(response.plan.plan);
    }
  };
  useEffect(() => {
    if (token) {
      plan(token);
    }
  }, []);
  return (
    <Fade in>
      <Box
        alignItems="stretch"
        sx={{
          textAlign: 'left',
          margin: '0',
          padding: '0',
        }}>
        <Box sx={{ paddingX: '10vw' }}>
          <h3 className="App-logo-text">Mi Plan 🔥</h3>
        </Box>
        <Box
          sx={{
            marginY: '0',
            paddingX: '10vw',
            paddingY: '0',
            display: 'flex',
            gap: 1,
            py: 1,
            overflow: 'auto',
            width: '100vw',
            scrollSnapType: 'x mandatory',
            '& > *': {
              scrollSnapAlign: 'center',
            },
            '::-webkit-scrollbar': { display: 'none' },
          }}>
          <PreviewCard
            title="Continúa con TU plan"
            callToAction={hasPlan ? <Cta1 url={myPlan} /> : <Cta0 />}
            urlParams="continue"
            imgUrl={setCover()}
            subtitle={setCountPhrase(count, risk)}
          />
          {hasPlan && (
            <PreviewCard
              title={setCTA(priority1)}
              callToAction={<Cta2 url={priority1} />}
              urlParams="continue"
              imgUrl={setImgUrl(priority1)}
            />
          )}
          {hasPlan && (
            <PreviewCard
              title={setCTA(priority2)}
              callToAction={<Cta3 url={priority2} />}
              urlParams="continue"
              imgUrl={setImgUrl(priority2)}
            />
          )}
          {hasPlan && (
            <PreviewCard title="Quieres cambiar tus preferencias?" callToAction={<Cta4 />} urlParams="continue" />
          )}
        </Box>
      </Box>
    </Fade>
  );
};

export default PlanCarousel;
