// gets the gradient color by weight
export const pickHex = (color1: string, color2: string, weight: number) => {
  const w1 = 1 - weight;
  const w2 = weight;
  const rgb = [
    Math.round(parseInt(color1.substring(1, 3), 16) * w1 + parseInt(color2.substring(1, 3), 16) * w2),
    Math.round(parseInt(color1.substring(3, 5), 16) * w1 + parseInt(color2.substring(3, 5), 16) * w2),
    Math.round(parseInt(color1.substring(5), 16) * w1 + parseInt(color2.substring(5), 16) * w2),
  ];
  return `#${rgb[0].toString(16)}${rgb[1].toString(16)}${rgb[2].toString(16)}`;
};

// Set the number of the month that starts x weeks from the date
export const getMonth = (weeks: number, since: Date) => {
  const lowerEnd = new Date(since.toISOString());
  lowerEnd.setDate(lowerEnd.getDate() + weeks * 7 - lowerEnd.getDay());
  const upperEnd = new Date(since.toISOString());
  upperEnd.setDate(upperEnd.getDate() + weeks * 7 + (6 - upperEnd.getDay()));
  const first = upperEnd;
  upperEnd.setDate(1);
  if (lowerEnd <= first && first <= upperEnd) {
    return first.getMonth();
  }
  return 12;
};

// set color according to value
export const getHex = (x: number) => {
  let hex = '';
  const hex1 = '#c8c8c8';
  const hex2 = '#1b4ed8';
  if (x === 0) {
    hex = pickHex(hex1, hex2, 0);
  } else if (x === 1) {
    hex = pickHex(hex1, hex2, 0.25);
  } else if (x === 2) {
    hex = pickHex(hex1, hex2, 0.5);
  } else if (x === 3) {
    hex = pickHex(hex1, hex2, 0.75);
  } else {
    //if (x === 4) {
    hex = pickHex(hex1, hex2, 1.0);
  }
  return hex;
};

// get the amount of weeks to show in the chart
export const getWeeks = (numDays: number, until: Date) => {
  const total = Math.floor(numDays / 7);
  const remainder = numDays % 7;
  return remainder === 0 && until.getDay() === 0 ? total : remainder <= until.getDay() ? total + 1 : total + 2;
};

// shit dates
export const shiftDate = (date: Date, numDays: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};
