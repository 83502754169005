import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import userService from '../../services/UserServices';
import { userActions } from '../../redux/slices/userSlice';
import { Box, Button } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';

const UserLogin: FC = () => {
  const dispatch = useDispatch();
  const phone = '56998408511';
  const password = 'aaaaaa1';
  const login = async () => {
    const response = await userService.loginUser({ phone, password });
    console.log(response);
    if (response) {
      const { user } = response;
      user.token = response.token;
      user.redirect = response.redirect;
      dispatch(userActions.login(user));
    }
  };
  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} width={'200px'} />
      </Box>
      <Button
        onClick={login}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
        variant="contained">
        INICIAR DEMO
      </Button>
    </Box>
  );
};

export default UserLogin;
