import React, { FC, useState } from 'react';
import { Chip, Box } from '@mui/material';
import BodyFrontMap from './BodyFrontMap';
import BodyBackMap from './BodyBackMap';

enum Show {
  front,
  back,
}

const BodyMap: FC = () => {
  const [showing, setShowing] = useState(Show.front);
  const changeView = () => {
    setShowing(showing === Show.front ? Show.back : Show.front);
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'inline-block', minWidth: '140px', height: '70vh', marginY: '0px', paddingY: '0px' }}>
        {showing === Show.front ? <BodyFrontMap /> : <BodyBackMap />}
        <Box>
          <Chip
            color="secondary"
            sx={{
              border: '1px solid #1b4ed8',
              fontWeight: 'bold',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            }}
            clickable
            onClick={changeView}
            label="CAMBIAR VISTA"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BodyMap;
