import { createTheme } from '@mui/material/styles';

const DefaultTheme = createTheme({
  palette: {
    primary: { main: '#1b4ed8', dark: '#2468e5', light: '#0068ff' },
    secondary: { main: '#7be7c6', dark: '#8bf7d6' },
    error: { main: '#DC143C', light: '#CD5C5C' },
    warning: { main: '#FFFF66', dark: '#2468e5', light: '#2468e5' },
    success: { main: '#9ACD32', dark: '#2468e5', light: '#2468e5' },
    info: { main: '#e5e5e5', dark: '#2468e5', light: '#2468e5' },
    divider: '#2468e5',
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#1b4ed8',
      secondary: '#7be7c6',
      disabled: '#7be7c6',
    },
    action: {
      disabled: '#000000f0 !important',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          '&:hover .hover_slide_right': {
            right: '17px',
            opacity: '1',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormLabel-root, .MuiTypography-root': {
            color: '#1b4ed8',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiToggleButton-primary': {
            color: '#1b4ed8',
            backgroundColor: '#FFF',
            padding: '0px 0px',
            fontWeight: 'bold',
            '&.MuiToggleButtonGroup-grouped': {
              mx: 1,
              border: '1px solid #1b4ed8 !important',
            },
            '&.Mui-selected': {
              color: '#FFF',
              backgroundColor: '#1b4ed8',
            },
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            color: '#1b4ed8',
          },
        },
        {
          props: { color: 'info', size: 'small' },
          style: {
            color: '#666',
            fontWeight: 'bolder',
            backgroundColor: '#e5e5e5ff',
          },
        },
        {
          props: { color: 'info', size: 'medium' },
          style: {
            color: '#444',
            backgroundColor: '#e5e5e580',
            fontWeight: 'bolder',
          },
        },
      ],
    },
  },
});

export default DefaultTheme;
