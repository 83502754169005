import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { Filter } from '../redux/types/filterTypes';

const getFilters = async (): Promise<any> => {
  const response: AxiosResponse<Filter[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'get',
    url: `/api/filters`,
  });
  return response?.data;
};

const filterService = {
  getFilters,
};

export default filterService;
