// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor, buildInteractorDirectAction } from './base';
import { filterActions } from '../slices/filterSlice';
import filterService from '../../services/FilterServices';

export const getFiltersInteractor = buildInteractor(
  filterActions.loadingGetFilters,
  filterActions.successGetFilters,
  filterActions.errorGetFilters,
  filterService.getFilters,
);

export const updateFilterInteractor = buildInteractorDirectAction(filterActions.update);
