import React, { FC } from 'react';
import { Box } from '@mui/material';
import UserProfile from '../components/UserProfileComponents/UserProfile';
import RoutinePreview from '../components/OtherComponents/RoutinePreview';

const Profile: FC = () => {
  return (
    <Box sx={{ width: '100vw', height: '100%', minHeight: '100vh', paddingTop: '12vh' }}>
      <UserProfile />
      <RoutinePreview />
    </Box>
  );
};

export default Profile;
