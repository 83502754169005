export const language = {
  en: {
    days: ['', 'Mon', '', 'Wen', '', 'Fri', ''],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
  sp: {
    days: ['', 'Lun', '', 'Mie', '', 'Vie', ''],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic', ''],
  },
};
