import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import userService from '../../services/UserServices';
import { Card, CardActionArea, CardContent, Chip, Typography, Link } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';

interface plan {
  plan?: string;
  priority_1?: string;
  priority_2?: string;
  priority_part_1_id?: number;
  priority_part_2_id?: number;
}

interface Props {
  plan: string;
}

const Explanation: FC<Props> = (props: Props) => {
  let explanation = (
    <p>
      No has reportado molestias considerables, por lo que te recomendamos pasar por el servicio una vez por semana para{' '}
      <b>PREVENIR</b> molestias en el futuro. <b>RECUERDA</b> que tu cuerpo es tu principal herramienta de trabajo!
    </p>
  );
  if (props.plan === 'c3') {
    // Riesgo alto
    explanation = (
      <p>
        Has declarado tener molestias de <b>INTENSIDAD ALTA</b>, por lo que como equipo Makana te recomandamos pasar por
        el servicio como mínimo <b>4 VECES</b> por semana, y continuar con tu plan en cada sesión hasta <b>ELIMINAR</b>
        estas molestias.
      </p>
    );
  } else if (props.plan === 'c2') {
    // Riesgo medio
    explanation = (
      <p>
        Al reportar molestias de <b>INTENSIDAD MEDIA</b>, como equipo Makana te recomendamos visitar nuestro servicio un
        mínimo de <b>3 VECES</b> por semana. Continúa con tu plan en cada sesión hasta <b>ELIMINAR</b> estas molestias.
        ¡Estamos aquí para ayudarte!
      </p>
    );
  } else if (props.plan === 'c1') {
    // Riesgo bajo
    explanation = (
      <p>
        Has mencionado tener molestias <b>LEVES</b>, por lo que como equipo Makana te recomandamos utilizar el servicio
        como por lo menos <b>2 VECES</b> por semana, y continuar con tu plan en cada sesión hasta <b>ELIMINAR</b> estas
        molestias. <b>Recuerda</b> que tu bienestar es esencial para tu desempeño.
      </p>
    );
  }
  return explanation;
};

const setPlanBadge = (plan: string) => {
  let badge = 'PREVENTIVO';
  if (plan === 'c3') {
    badge = 'CORRECTIVO ALTO';
  } else if (plan === 'c2') {
    badge = 'CORRECTIVO MEDIO';
  } else if (plan === 'c1') {
    badge = 'CORRECTIVO BAJO';
  }
  return badge;
};

const setPriority = (priority: string) => {
  switch (priority[1]) {
    case '1':
      return 'Cuello';
    case '2':
      return 'Hombros';
    case '3':
      return 'Brazo / Codo';
    case '4':
      return 'Muñeca / Mano';
    case '5':
      return 'Espalda Alta';
    case '6':
      return 'Espalda Baja';
    case '7':
      return 'Caderas / Piernas';
    case '8':
      return 'Rodillas';
    case '9':
      return 'Tobillos / Pies';
    default:
      return 'No hay preferencia';
  }
};

const PlanPreview: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [myPlan, setMyPlan] = useState<plan>({});
  const plan = async (token: string) => {
    const response = await userService.getUserPlan(token);
    if (response.plan) {
      setMyPlan(response.plan);
    }
  };
  useEffect(() => {
    if (user.token) {
      plan(user.token);
    }
  }, []);
  return (
    <Fade in={true}>
      <Card
        sx={{
          gap: 2,
          spacing: 2,
          width: '100vw',
          maxWidth: '480px',
          position: 'relative',
          top: '0',
          border: 'none',
          boxShadow: 'none',
          backgroundImage: 'transparent',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'linear-gradient(to bottom right,#88b849, #7be7c6)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
            zIndex: '4',
            position: 'relative',
            top: '0',
            textAlign: 'left',
            color: 'white',
          }}>
          <CardContent>
            <Box sx={{ marginX: { xs: '5vw', sm: '5px' }, marginY: '0', paddingY: '0' }}>
              <Typography variant="h5">
                <b>TU</b> Plan:{' '}
                <Chip color="info" label={myPlan.plan ? setPlanBadge(myPlan.plan) : '-'} sx={{ margin: 0 }} />
              </Typography>
              {myPlan.plan && <Explanation plan={myPlan.plan} />}
              <Typography variant="h6">
                <b>TUS</b> Prioridades:
              </Typography>
              <Chip
                color="info"
                label={myPlan.priority_1 ? `1 - ${setPriority(myPlan.priority_1)}` : '-'}
                sx={{ marginRight: { xs: '5vw', sm: '5px' } }}
              />
              <Chip color="info" label={myPlan.priority_2 ? `2 - ${setPriority(myPlan.priority_2)}` : '-'} />
            </Box>
          </CardContent>
          <CardActionArea sx={{ width: '100%', paddingBottom: { xs: '5vw', sm: '25px' } }}>
            <Link
              sx={{
                color: 'white',
                minWidth: '60vw',
                fontWeight: 'bold',
                boxShadow: 'none',
                marginX: { xs: '10vw', sm: '25px' },
                textDecoration: 'none',
                fontSize: '1rem',
                borderBottom: '2px solid white',
              }}
              href={`/update_plan`}>
              Modificar mi Plan &gt;
            </Link>
          </CardActionArea>
        </Box>
      </Card>
    </Fade>
  );
};

export default PlanPreview;
