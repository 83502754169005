import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { AppBar, Container, Toolbar, Box } from '@mui/material';
import { Chip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import makana from './../images/logo.png';
import makana_small from './../images/logo_small.png';
import Timer from '../components/OtherComponents/Timer';

const MakanaBar: FC = () => {
  const { mode } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const exit = () => {
    navigate('/out');
  };

  return (
    <AppBar sx={{ background: 'white' }}>
      <Container>
        <Toolbar sx={{ postition: 'relative', top: '0' }}>
          {mode === 'active-out' ? <img src={makana_small} height={'30px'} /> : <img src={makana} height={'30px'} />}
          <Box sx={{ marginLeft: '10px', paddingBottom: '0', marginTop: '8px' }}>
            {mode === 'active-out' && <Timer />}
          </Box>
          <Box sx={{ position: 'fixed', right: '10vw', top: '16px' }}>
            {mode === 'active-out' && (
              <Box>
                <Chip onClick={exit} color="error" icon={<ExitToAppIcon />} label="Salir" />
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MakanaBar;
