import React, { FC } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Routes from './navigation/Routes';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DefaultTheme from './styles/DefaultTheme';
import * as protocolInteractors from './redux/interactors/ProtocolInteractors';
import * as categoryInteractors from './redux/interactors/CategoryInteractors';
import * as filterInteractors from './redux/interactors/FilterInteractors';
import './App.css';

interface DispatchProps {
  getProtocolsInteractor: typeof protocolInteractors.getProtocolsInteractor;
  getCategoriesInteractor: typeof categoryInteractors.getCategoriesInteractor;
  getFiltersInteractor: typeof filterInteractors.getFiltersInteractor;
}

const App: FC<DispatchProps> = (props: DispatchProps) => {
  props.getCategoriesInteractor([]);
  props.getProtocolsInteractor([]);
  props.getFiltersInteractor([]);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <div className="container">
        <div className="App">
          <Routes />
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...categoryInteractors,
      ...filterInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(App);
