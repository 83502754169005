import React, { FC } from 'react';
import { Box } from '@mui/material';
// import NordicForm from '../components/FormComponents/NordicForm';
import Nordic2 from '../components/FormComponents/Nordic/Nordic2';

const NordicScreen: FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
        paddingTop: '10vh',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box>
          <Nordic2 />
        </Box>
      </Box>
    </Box>
  );
};

export default NordicScreen;
