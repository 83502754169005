import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import MenuCategory from '../components/HomeScreenComponents/Category';
import HomeFilters from '../components/HomeScreenComponents/HomeFilters';
// import Protocol from '../components/OtherComponents/Protocol';
import { Box, Link } from '@mui/material';
import RoutinePreview from '../components/OtherComponents/RoutinePreview';
import PlanCarousel from '../components/WorkoutPlan/PlanCarousel';
import stock_8 from './../images/stock_8.png';

const Home: FC = () => {
  const categories = useSelector((state: RootState) => state.category.categories);
  const name = useSelector((state: RootState) => state.user.first_name);
  const sex = useSelector((state: RootState) => state.user.sex);
  return (
    <Box sx={{ paddingTop: '10vh' }}>
      <Box sx={{ textAlign: 'left', marginX: '5vw', marginY: '0', paddingY: '0' }}>
        <h2 className="App-logo-text">Hola, {sex === 'F' ? `${name}! 👩‍🚀` : `${name}! 👨‍🚀`}</h2>
      </Box>
      <PlanCarousel />
      <Box
        sx={{
          backgroundImage: 'url(https://cdn.makana.cl/mkn-591.jpg)',
          backgroundSize: `100vw auto`,
          backgroundPosition: 'center',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          margin: '0vh',
          paddingBottom: '0vh',
        }}>
        <Box
          sx={{
            backgroundImage: 'linear-gradient(to bottom right,#0068ffc0, #7be7c660)',
            paddingBottom: '8vh',
            paddingTop: '2vh',
          }}>
          <Box sx={{ marginX: '5vw', marginY: '5vw', paddingY: '0' }}>
            <h1 className="App-profile-text">Quires probar algo NUEVO?</h1>
          </Box>
          <Link
            href="/charlie"
            sx={{
              color: 'primary.main',
              backgroundColor: '#FFFFFFc0',
              width: '100%',
              borderRadius: '50px',
              fontWeight: 'bold',
              boxShadow: 'none',
              paddingY: '2vh',
              paddingX: '5vw',
              textDecoration: 'none',
            }}>
            ENCUENTRA TU RUTINA ACÁ
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: '0vh',
          paddingBottom: '15vh',
          overflow: 'scroll',
          minHeight: '100vh',
          height: '100%',
          backgroundImage: `url(${stock_8})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left top',
        }}>
        <HomeFilters />
        {categories.map((category, index) => (
          <MenuCategory key={`category-${index}`} specs={category} />
        ))}
      </Box>
      <RoutinePreview />
    </Box>
  );
};

export default Home;
