import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { UserState } from '../redux/types/userTypes';

const ChatNTalkScreen: FC = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const user: UserState = useSelector((state: RootState) => state.user);
  return (
    <Box sx={{ paddingTop: '10vh', width: '100vw', height: '100vh', display: 'block' }}>
      <Button sx={{ marginTop: '5px', color: 'primary.main', fontWeight: 'bold' }} onClick={goBack}>
        Volver
      </Button>
      <Box sx={{ width: '100vw', height: '80vh' }}>
        <iframe
          src={`https://client.consolto.com/expert/pedro.maza?cFirstName=${user.first_name}&cLastName=${user.last_name}&cEmail=${user.email}&cMessage=https://makana.cl/users/${user.id}`}
          allow="camera;microphone;fullscreen;autoplay;display-capture"
          frameBorder="0"
          scrolling="no"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};

export default ChatNTalkScreen;
