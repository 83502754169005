import React, { FC } from 'react';
import { RootState } from '../../../redux/store';
import { userActions } from '../../../redux/slices/userSlice';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import userService from '../../../services/UserServices';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import * as protocolInteractors from '../../../redux/interactors/ProtocolInteractors';
import { getRecomendation } from '../../Recommender/tree';

interface StateProps {
  // add state props here
  disabled: boolean;
}

interface DispatchProps {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
  resetNordicFormInteractor: typeof NordicFormInteractors.resetNordicFormInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const NordicSubmit: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_id = useSelector((state: RootState) => state.user.id);
  const business_id = useSelector((state: RootState) => state.user.business_id);
  const {
    firstCheck,
    weekCheck,
    weekScale,
    lowerBackDetails,
    neckDetails,
    shouldersDetails,
    elbowsExtra,
    handsExtra,
    upperBackExtra,
    legsExtra,
    kneesExtra,
    anklesExtra,
  } = useSelector((state: RootState) => state.nordicForm);
  const postNordic = async () => {
    const response = await userService.postNordic(
      firstCheck.cuello,
      firstCheck.hombro,
      firstCheck.brazoCodo,
      firstCheck.manoMuñeca,
      firstCheck.espaldaAlta,
      firstCheck.espaldaBaja,
      firstCheck.caderaPierna,
      firstCheck.rodilla,
      firstCheck.tobilloPie,
      weekCheck.cuello,
      weekCheck.hombro,
      weekCheck.brazoCodo,
      weekCheck.manoMuñeca,
      weekCheck.espaldaAlta,
      weekCheck.espaldaBaja,
      weekCheck.caderaPierna,
      weekCheck.rodilla,
      weekCheck.tobilloPie,
      firstCheck.espaldaBaja,
      firstCheck.cuello,
      firstCheck.hombro,
      weekScale?.cuello,
      weekScale?.hombro,
      weekScale?.brazoCodo,
      weekScale?.manoMuñeca,
      weekScale?.espaldaAlta,
      weekScale?.espaldaBaja,
      weekScale?.caderaPierna,
      weekScale?.rodilla,
      weekScale?.tobilloPie,
      lowerBackDetails?.hospitalized,
      lowerBackDetails?.changeJobs,
      lowerBackDetails?.totalTime,
      lowerBackDetails?.workActivity,
      lowerBackDetails?.leisureActivity,
      lowerBackDetails?.preventedTime,
      lowerBackDetails?.medicalCare,
      lowerBackDetails?.weekProblems,
      lowerBackDetails?.trauma,
      lowerBackDetails?.swelling,
      neckDetails?.hospitalized,
      neckDetails?.changeJobs,
      neckDetails?.totalTime,
      neckDetails?.workActivity,
      neckDetails?.leisureActivity,
      neckDetails?.preventedTime,
      neckDetails?.medicalCare,
      neckDetails?.weekProblems,
      neckDetails?.trauma,
      neckDetails?.swelling,
      shouldersDetails?.hospitalized,
      shouldersDetails?.changeJobs,
      shouldersDetails?.totalTime,
      shouldersDetails?.workActivity,
      shouldersDetails?.leisureActivity,
      shouldersDetails?.preventedTime,
      shouldersDetails?.medicalCare,
      shouldersDetails?.weekProblems,
      shouldersDetails?.trauma,
      shouldersDetails?.swelling,
      elbowsExtra?.totalTime,
      elbowsExtra?.trauma,
      elbowsExtra?.swelling,
      handsExtra?.totalTime,
      handsExtra?.trauma,
      handsExtra?.swelling,
      upperBackExtra?.totalTime,
      upperBackExtra?.trauma,
      upperBackExtra?.swelling,
      legsExtra?.totalTime,
      legsExtra?.trauma,
      legsExtra?.swelling,
      kneesExtra?.totalTime,
      kneesExtra?.trauma,
      kneesExtra?.swelling,
      anklesExtra?.totalTime,
      anklesExtra?.trauma,
      anklesExtra?.swelling,
      user_id,
    );
    const plan = response.plan.priority_1;
    let newI = response.plan.x ? Math.round(response.plan.x / 2) : 1;
    if (newI <= 2) {
      newI = 2;
    } else if (newI <= 4) {
      newI = 3;
    } else {
      newI = 4;
    }
    const recomendation = getRecomendation(`${plan?.substring(0, 6)}i${newI}${plan?.substring(8)}`);
    props.resetNordicFormInteractor();
    if (business_id !== null && business_id !== undefined) {
      dispatch(userActions.setMode({ mode: 'active-out' }));
      props.selectProtocolInteractor(recomendation.id);
      navigate('/routine');
    } else {
      navigate('/activate');
    }
  };
  return (
    <Button
      disabled={props.disabled}
      onClick={postNordic}
      sx={{ borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
      variant="contained"
      size="small">
      ENVIAR
    </Button>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
      ...protocolInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicSubmit);
