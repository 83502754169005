import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import NavBar from './NavBar';
import MakanaBar from './AppBar';
import Home from '../screens/Home';
import Profile from '../screens/Profile';
import BodyMapScreen from '../screens/BodyMap';
import Login from '../screens/Login';
import NordicScreen from '../screens/Nordic';
import SofiScreen from '../screens/Sofi';
import RegisterIn from '../screens/RegisterIn';
import RegisterOut from '../screens/RegisterOut';
import ChatNTalkScreen from '../screens/ChatNTalk';
import RoutineScreen from '../screens/Routine';
import Consolto from '../components/OtherComponents/Consolto';
import RecommenderScreen from '../screens/Recommender';
import UpdatePlan from '../screens/UpdatePlan';
import ScrollToTop from './ScrollToTop';

const myRoutes: FC = () => {
  const { token, business_id, redirect } = useSelector((state: RootState) => state.user);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <MakanaBar />
      <Routes>
        <Route path="/" element={token ? <Navigate to="/home" /> : <Login />} />
        <Route path="/login" element={token ? <Navigate to="/home" /> : <Login />} />
        <Route path="/profile" element={business_id && token ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/body" element={business_id && token ? <BodyMapScreen /> : <Navigate to="/login" />} />
        <Route path="/nordic" element={token ? <NordicScreen /> : <Navigate to="/login" />} />
        <Route path="/sofi" element={business_id && token ? <SofiScreen /> : <Navigate to="/login" />} />
        <Route path="/routine" element={business_id && token ? <RoutineScreen /> : <Navigate to="/login" />} />
        <Route
          path="/in/:plan"
          element={
            business_id && token && redirect ? (
              redirect === 'nordic' ? (
                <Navigate to="/nordic" />
              ) : redirect === 'sofi' ? (
                <Navigate to="/sofi" />
              ) : (
                <RegisterIn />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/out"
          element={
            business_id && token && redirect ? (
              redirect === 'sofi' ? (
                <Navigate to="/sofi" />
              ) : (
                <RegisterOut />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/chat" element={business_id && token ? <ChatNTalkScreen /> : <Navigate to="/login" />} />
        <Route path="/home" element={business_id && token && <Home />} />
        <Route path="/charlie" element={<RecommenderScreen />} />
        <Route path="/update_plan" element={token ? <UpdatePlan /> : <Navigate to="/login" />} />
      </Routes>
      {token && business_id && <NavBar />}
      <Consolto />
    </Router>
  );
};

export default myRoutes;
