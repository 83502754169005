import React, { FC } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { ReactComponent as YourSvg } from '../../../images/nordico.svg';

const NordicFormIntro: FC = () => {
  const { first_name } = useSelector((state: RootState) => state.user);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        overflow: 'visible',
      }}>
      <Box
        sx={{
          display: 'block',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}>
        <Box sx={{ marginTop: '5vh', marginX: '20vw', width: '60vw', maxWidth: '300px', maxHeight: '50vh' }}>
          <YourSvg />
        </Box>
        <Box sx={{ marginX: '5vw', width: '90vw' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Hola {first_name}!!
          </Typography>
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            Contesta el siguiente cuestionario a conciencia para poder recomendarte rutinas a TU medida y tener una{' '}
            <b>mejor experiencia</b> en las estaciones de <b>makana</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NordicFormIntro;
