import { ProtocolsState } from '../types/protocolTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';

const initialState: ProtocolsState = {
  protocolHidden: true,
  protocols: [],
  getProtocolsStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successGetProtocols = (state: ProtocolsState, action: Action) => {
  return {
    ...state,
    protocols: [{ id: 0 }, ...action.payload],
    getProtocolsStatus: { loading: false, success: true, error: false },
  };
};

const ProtocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('getProtocols', initialState, null, successGetProtocols),
    select: (state: ProtocolsState, action: Action) => {
      const selected = state.protocols.filter((x) => +x.id === +action.payload)[0];
      return {
        ...state,
        protocol: selected,
        protocolHidden: false,
      };
    },
    unselect: (state: ProtocolsState) => {
      return {
        ...state,
        protocolHidden: true,
        protocol: null,
      };
    },
  },
});

export const protocolActions = ProtocolSlice.actions;
export const protocolReducer = ProtocolSlice.reducer;
