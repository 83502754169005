import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { Protocol } from '../redux/types/protocolTypes';

const getProtocols = async (): Promise<any> => {
  const response: AxiosResponse<Protocol[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'get',
    url: `/api/protocols`,
  });
  return response?.data;
};

const protocolService = {
  getProtocols,
};

export default protocolService;
