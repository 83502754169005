import React, { FC } from 'react';
import { Fade } from '@mui/material';
import { Box } from '@mui/material';

interface Props {
  title: string;
  callToAction: any;
  urlParams: string;
  imgUrl?: string;
  subtitle?: string;
}

const PreviewCard: FC<Props> = (props: Props) => {
  return (
    <Fade in={true}>
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: props.imgUrl ? `url(${props.imgUrl})` : 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
          backgroundRepeat: `no-repeat`,
          backgroundSize: `400px auto`,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          margin: '0vh 5vw 5vw 0vw',
          paddingBottom: '3vh',
          minWidth: {
            xs: '80vw', // Dispositivos móviles
            sm: '400px', // Otros tamaños de pantalla
          },
        }}>
        <Box sx={{ marginX: { xs: '5vw', sm: '30px' }, marginY: { xs: '5vw', sm: '20px' }, paddingY: '0' }}>
          <h2 className="App-profile-text">{props.title}</h2>
          {props.subtitle && <h3 className="App-profile-text plan">{props.subtitle}</h3>}
        </Box>
        {props.callToAction}
      </Box>
    </Fade>
  );
};

export default PreviewCard;
