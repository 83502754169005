import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import userService from '../../services/UserServices';
import { userActions } from '../../redux/slices/userSlice';
import * as SofiFormInteractors from '../../redux/interactors/SofiFormInteractors';
import { Box, Typography, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import { ReactComponent as YourSvg } from '../../images/sofi.svg';
import SofiFormTable2 from './SofiFormTable2';

// Add stepper to form https://mui.com/material-ui/react-stepper/
// https://web.archive.org/web/20200809175125/https://www.netguru.com/codestories/few-tips-that-will-make-your-pwa-on-ios-feel-like-native

interface StateProps {
  // add state props here
}

interface DispatchProps {
  resetInteractor: typeof SofiFormInteractors.resetInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const SofiForm: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    props.resetInteractor();
  }, []);
  const navigate = useNavigate();
  const user_id = useSelector((state: RootState) => state.user.id);
  const { first_name } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.user.mode);
  const {
    extenuado,
    agotado,
    exhausto,
    dificultadRespiratoria,
    palpitaciones,
    calor,
    articulacionesAgarrotadas,
    entumecido,
    dolorido,
    apatico,
    pasivo,
    indiferente,
    somnoliento,
    durmiendome,
    bostezandome,
    irritable,
    enojado,
    furioso,
  } = useSelector((state: RootState) => state.sofiForm);
  const { token } = useSelector((state: RootState) => state.business);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 7;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const postSofi = () => {
    userService.postSofi(
      extenuado,
      agotado,
      exhausto,
      dificultadRespiratoria,
      palpitaciones,
      calor,
      articulacionesAgarrotadas,
      entumecido,
      dolorido,
      apatico,
      pasivo,
      indiferente,
      somnoliento,
      durmiendome,
      bostezandome,
      irritable,
      enojado,
      furioso,
      user_id,
    );
    props.resetInteractor();
    if (mode === 'active-out') {
      dispatch(userActions.setStarted({ started: undefined }));
      dispatch(userActions.setMode({ mode: '' }));
      if (token) {
        dispatch(userActions.logout());
        navigate('/fast_login');
      } else {
        dispatch(userActions.setMode({ mode: '' }));
        navigate('/home');
      }
    } else if (mode === 'active-in') {
      dispatch(userActions.setMode({ mode: 'active-out' }));
      navigate('/body');
    } else {
      navigate('/body');
    }
    // fix sofi on exit
  };

  return (
    <Box>
      <Box sx={{ width: '500px', maxWidth: '92vw' }}>
        <Box sx={{ borderRadius: '25px 25px 0 0', padding: '20px' }}>
          <Typography variant="h4">
            <span className="App-logo-text">INDICE DE FATIGA</span>
          </Typography>
        </Box>
        <SwipeableViews disabled index={activeStep}>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Box sx={{ marginX: '21vw', width: '50vw', maxWidth: '200px', maxHeight: '50vh' }}>
              <YourSvg />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              Hola {first_name}!!
            </Typography>
            <Typography sx={{ marginY: '20px' }}>
              Indica como te sientes evaluando del 0 al 10 las siguientes categorias. Utiliza la escala que está por
              debajo de cada expresión donde 0 corresponde a <b>Nada en Absoluto</b> y 10 a <b>En Alto Grado</b>.
            </Typography>
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Falta de Energia</span>
            </Typography>
            <SofiFormTable2
              name="energy"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'extenuado', tag: 'Extenuado' },
                { name: 'agotado', tag: 'Agotado' },
                { name: 'exhausto', tag: 'Exhausto' },
              ]}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Cansancio Fisico</span>
            </Typography>
            <SofiFormTable2
              name="tiredness"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'dificultadRespiratoria', tag: 'Respirando con dificultad' },
                { name: 'palpitaciones', tag: 'Palpitaciones' },
                { name: 'calor', tag: 'Con Calor' },
              ]}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Malestar Fisico</span>
            </Typography>
            <SofiFormTable2
              name="unwell"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'articulacionesAgarrotadas', tag: 'Con articulaciones agarrotadas' },
                { name: 'entumecido', tag: 'Entumecido' },
                { name: 'dolorido', tag: 'Dolorido' },
              ]}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Falta de Motivacion</span>
            </Typography>
            <SofiFormTable2
              name="motivation"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'apatico', tag: 'Apático' },
                { name: 'pasivo', tag: 'Pasivo' },
                { name: 'indiferente', tag: 'Indiferente' },
              ]}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Somnolencia</span>
            </Typography>
            <SofiFormTable2
              name="sleepy"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'somnoliento', tag: 'Somnoliento' },
                { name: 'durmiendome', tag: 'Durmiéndome' },
                { name: 'bostezandome', tag: 'Bostezándome' },
              ]}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '500px',
              maxWidth: '92vw',
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
            }}>
            <Typography variant="h5" sx={{ marginX: '20px', borderBottom: '1px solid grey' }}>
              <span className="App-logo-text">Irritabilidad</span>
            </Typography>
            <SofiFormTable2
              name="angry"
              valueRange={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              columnVars={[
                { name: 'irritable', tag: 'Irritable' },
                { name: 'enojado', tag: 'Enojado' },
                { name: 'furioso', tag: 'Furioso' },
              ]}
            />
          </Box>
        </SwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <Button
                sx={{ fontWeight: 'bold', borderRadius: '50px' }}
                variant="contained"
                size="small"
                onClick={postSofi}
                aria-label="next">
                Enviar
              </Button>
            ) : (
              <Button
                sx={{ fontWeight: 'bold', borderRadius: '50px' }}
                variant="contained"
                size="small"
                onClick={handleNext}
                aria-label="next">
                Siguiente
              </Button>
            )
          }
          backButton={
            activeStep === 0 ? (
              <Button sx={{ color: '#bbb', fontWeight: 'bold' }} size="small" aria-label="back"></Button>
            ) : (
              <Button
                sx={{ fontWeight: 'bold' }}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                aria-label="back">
                Atrás
              </Button>
            )
          }
          sx={{ borderRadius: '0 0 25px 25px', width: '500px', maxWidth: '92vw' }}
        />
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...SofiFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(SofiForm);
