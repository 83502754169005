// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor } from './base';
import { categoryActions } from '../slices/categorySlice';
import categoryService from '../../services/CategoryServices';

export const getCategoriesInteractor = buildInteractor(
  categoryActions.loadingGetCategories,
  categoryActions.successGetCategories,
  categoryActions.errorGetCategories,
  categoryService.getCategories,
);
