import React, { FC, useState, MouseEvent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import { Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface StateProps {
  name: string;
  valueRange: number[];
  tag: string;
}

interface DispatchProps {
  updateWeekScaleInteractor: typeof NordicFormInteractors.updateWeekScaleInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const pickHex = (color1: number[], color2: number[], weight: number) => {
  const w1 = weight;
  const w2 = 1 - w1;
  const rgb = [
    Math.round(+color1[0] * w1 + +color2[0] * w2),
    Math.round(+color1[1] * w1 + +color2[1] * w2),
    Math.round(+color1[2] * w1 + +color2[2] * w2),
  ];
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

export const NordicTableRow: FC<Props> = (props: Props) => {
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [label, setLabel] = useState('');
  const { valueRange, name } = props;

  const handleChange = (event: MouseEvent<HTMLElement>, newValue: number) => {
    setSelectedValue(newValue);
    props.updateWeekScaleInteractor({ name: props.name, value: newValue });
    if (newValue <= 3) {
      setLabel('Intensidad BAJA');
    } else if (newValue <= 6) {
      setLabel('Intensidad MEDIA');
    } else if (newValue <= 8) {
      setLabel('Intensidad ALTA');
    } else {
      setLabel('Intensidad MUY ALTA');
    }
  };
  return (
    <>
      <Grid container sx={{ marginY: '0px', paddingY: '0px', marginX: '0px', paddingX: '0px' }}>
        <Grid item xs={12} sx={{ fontSize: 'medium', borderBottom: 'none', margin: '0px', padding: '0px' }}>
          <p>
            <b>{props.tag}:</b> {label}
          </p>
        </Grid>
      </Grid>
      <Grid container sx={{ marginY: '0px', paddingY: '0px', marginX: '0px', paddingX: '0px' }}>
        <Grid item xs={12} sx={{ borderBottom: 'none', margin: '0px', padding: '0px' }}>
          <ToggleButtonGroup
            sx={{ width: '100%' }}
            color="primary"
            value={selectedValue}
            exclusive
            onChange={handleChange}
            aria-label="Platform">
            {valueRange.map((v, i) => (
              <ToggleButton
                key={`${name}-cell-${v}`}
                value={v}
                sx={{
                  marginX: '5px',
                  color:
                    i * 2 > valueRange.length
                      ? pickHex([255, 0, 0], [255, 255, 50], +(i - 5) / 5)
                      : pickHex([255, 255, 50], [0, 255, 0], +i / 5),
                  fontWeight: 'bold',
                  height: '9vw',
                  width: '10vw',
                  '&.MuiToggleButton-standard': {
                    borderRadius: '100px',
                    height: '9vw',
                    width: '10vw',
                    marginX: '5px',
                  },
                  '&.Mui-selected, &.Mui-selected:hover': {
                    borderRadius: '100px',
                    height: '9vw',
                    width: '10vw',
                    background:
                      i * 2 > valueRange.length
                        ? pickHex([255, 0, 0], [255, 255, 50], +(i - 5) / 5)
                        : pickHex([255, 255, 50], [0, 255, 0], +i / 5),
                    color: 'white',
                    fontWeight: 'bold',
                  },
                  '&.MuiToggleButtonGroup-grouped': {
                    borderRadius: '100px !important',
                    border: 'none',
                    marginY: '0',
                    marginX: '5px',
                  },
                }}>
                {v}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container sx={{ marginY: '0px', paddingY: '0px', marginX: '0px', paddingX: '0px' }}>
        <Grid item xs={6} sx={{ color: '#bbb', fontWeight: 'bold', textAlign: 'left' }}>
          {'< BAJO dolor'}
        </Grid>
        <Grid item xs={6} sx={{ color: '#bbb', fontWeight: 'bold', textAlign: 'right' }}>
          {'ALTO dolor >'}
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicTableRow);
