import React, { FC } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import { Filter } from '../../redux/types/filterTypes';
import * as filterInteractors from '../../redux/interactors/FilterInteractors';

interface StateProps {
  filter: Filter;
}

interface DispatchProps {
  updateFilterInteractor: typeof filterInteractors.updateFilterInteractor;
}

interface Props extends StateProps, DispatchProps {
  // Add in here other props if needed
}

const ChipFilter: FC<Props> = (props: Props) => {
  const clickHanlder = () => {
    props.updateFilterInteractor(+props.filter.id);
  };
  return (
    <Chip
      variant={props.filter.active ? 'filled' : 'outlined'}
      color="secondary"
      clickable={true}
      label={props.filter.name.toUpperCase()}
      onClick={clickHanlder}
      sx={{ fontWeight: 'bold' }}
    />
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...filterInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(ChipFilter);
