// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor, buildInteractorDirectAction, buildInteractorDirectActionNoParams } from './base';
import { protocolActions } from '../slices/protocolSlice';
import protocolService from '../../services/ProtocolServices';

export const getProtocolsInteractor = buildInteractor(
  protocolActions.loadingGetProtocols,
  protocolActions.successGetProtocols,
  protocolActions.errorGetProtocols,
  protocolService.getProtocols,
);

export const selectProtocolInteractor = buildInteractorDirectAction(protocolActions.select);
export const unselectProtocolInteractor = buildInteractorDirectActionNoParams(protocolActions.unselect);
