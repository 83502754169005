import React, { FC, useState, ChangeEvent } from 'react';
import { Box, FormControl, FormLabel, InputLabel, Select } from '@mui/material';
import { MenuItem, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

interface Props {
  title: string;
  description?: string;
  blockedPreference: string;
  priority: string;
  onChange?: (priority: string) => void;
}

enum TotalTime {
  Week = '0 a 7 dias',
  Month = '8 a 30 dias',
  More = 'Más de 30 dias',
  All = 'Todos los dias',
}

const encondeSwelling = (swelling: boolean) => {
  return swelling ? 'h1' : 'h0';
};

const encodeTrauma = (trauma: boolean) => {
  return trauma ? 't1' : 't0';
};

const encodePreference = (location: string, appearance: string, swelling: boolean, trauma: boolean) => {
  return `${location}-${appearance}-IX-${encondeSwelling(swelling)}-${encodeTrauma(trauma)}`;
};

const bodyParts = [
  { id: 'l1', value: 'Cuello' },
  { id: 'l2', value: 'Hombros' },
  { id: 'l3', value: 'Brazo/Codo' },
  { id: 'l4', value: 'Mano/Muñeca' },
  { id: 'l5', value: 'Espalda Alta' },
  { id: 'l6', value: 'Espalda Baja' },
  { id: 'l7', value: 'Cadera/Pierna' },
  { id: 'l8', value: 'Rodilla' },
  { id: 'l9', value: 'Tobillo/Pie' },
];

const UpdatePriority: FC<Props> = (props: Props) => {
  const [location, setLocation] = useState<string>(props.priority.substring(0, 2));
  const [appearance, setAppearance] = useState<string>(props.priority.substring(3, 5));
  const [swelling, setSwelling] = useState<boolean>(props.priority.substring(9, 11) === 'h1');
  const [trauma, setTrauma] = useState<boolean>('t1' === props.priority.substring(12, 14));

  const locationOptions = bodyParts.filter((part) => part.id !== props.blockedPreference);

  const handleLocation = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);
    if (props.onChange) {
      props.onChange(encodePreference(event.target.value, appearance, swelling, trauma));
    }
  };

  const handleAppearance = (event: SelectChangeEvent<string>) => {
    setAppearance(event.target.value);
    if (event.target.value !== 'a1') {
      setSwelling(false);
      setTrauma(false);
    }
    if (props.onChange) {
      props.onChange(encodePreference(location, event.target.value, swelling, trauma));
    }
  };

  const handleTrauma = (event: ChangeEvent<HTMLInputElement>) => {
    setTrauma(event.target.value === 'true');
    if (props.onChange) {
      props.onChange(encodePreference(location, appearance, swelling, event.target.value === 'true'));
    }
  };

  const handleSwelling = (event: ChangeEvent<HTMLInputElement>) => {
    setSwelling(event.target.value === 'true');
    if (props.onChange) {
      props.onChange(encodePreference(location, appearance, event.target.value === 'true', trauma));
    }
  };

  return (
    <Box sx={{ width: '100vw', paddingX: '5vw' }}>
      <h3>{props.title}</h3>
      <b>{props.description}</b>
      <FormControl sx={{ width: '100%', marginTop: '5%' }}>
        <InputLabel id="Sselect-label">Elige una part del cuerpo</InputLabel>
        <Select
          value={location}
          onChange={handleLocation}
          labelId="Sselect-label"
          id="simple-select"
          label="Elige una part del cuerpo"
          variant="outlined"
          sx={{ width: '100%' }}>
          {locationOptions.map((part) => (
            <MenuItem key={part.id} value={part.id}>
              {part.value}
            </MenuItem>
          ))}
        </Select>
        <FormLabel sx={{ color: '#1b4ed8 !important' }}>
          ¿Cúal es el tiempo total que has tenido problemas en esta parte durante los <b>últimos 12 meses</b>?
        </FormLabel>
        <RadioGroup
          value={appearance}
          onChange={handleAppearance}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group">
          <FormControlLabel value="a1" control={<Radio />} label={TotalTime.Week} />
          <FormControlLabel value="a2" control={<Radio />} label={TotalTime.Month} />
          <FormControlLabel value="a3" control={<Radio />} label={TotalTime.More} />
          <FormControlLabel value="a4" control={<Radio />} label={TotalTime.All} />
        </RadioGroup>
        {appearance === 'a1' && (
          <>
            <FormLabel sx={{ color: '#1b4ed8 !important' }}>
              ¿Tu molestia es producto de un <b>golpe, caída o torcedura</b> reciente?
            </FormLabel>
            <RadioGroup
              value={trauma}
              onChange={handleTrauma}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group">
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Si" />
            </RadioGroup>
            <FormLabel sx={{ color: '#1b4ed8 !important' }}>
              ¿Presentas <b>hinchazón o inflamación</b> en la zona?
            </FormLabel>
            <RadioGroup
              value={swelling}
              onChange={handleSwelling}
              aria-labelledby="demo-radio-buttons-group-labfel"
              name="radio-buttons-group">
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Si" />
            </RadioGroup>
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default UpdatePriority;
