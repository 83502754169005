// id = 0 es asistecia en linea
// id =  NaN para protocolos no grabados
// REVISAR EN MIRO -> CAMBIOS PEDRO
interface type_x extends NonNullable<unknown> {
  [index: string]: any;
}

interface rec {
  id: number;
}

export const lx: type_x = {
  l1: 'cuello',
  l2: 'hombros',
  l3: 'brazo / codo',
  l4: 'muñeca / mano',
  l5: 'espalda alta',
  l6: 'espalda baja / gluteo',
  l7: 'pierna',
  l8: 'rodilla',
  l9: 'tobillo / pie',
};

export const ax: type_x = {
  a1: '1 sem',
  a2: '1 sem - 1 mes',
  a3: '1 mes - 2 mes',
  a4: '+ 2 meses',
};

export const ix: type_x = {
  i1: 'malestar leve',
  i2: 'malestar moderado',
  i3: 'malestar intenso',
};

export const hx: type_x = {
  h0: 'sin hinchazón',
  h1: 'hinchazón',
};

export const tx: type_x = {
  t0: 'sin trauma',
  t1: 'trauma',
};

export const getRecomendation = (path: string) => {
  const pathArray = path.split('-');
  const [l, a, i, h, t] = pathArray;
  let recomendation = recomendador.molestias[l][a][i + h + t];
  if (recomendation === undefined) {
    recomendation = recomendador.molestias[l][a][i];
  }
  const elementosValidos = recomendation.filter((item: rec) => !isNaN(item.id) && item.id !== 0);
  return elementosValidos[0];
};

const recomendador: type_x = {
  molestias: {
    l1: {
      a1: {
        i1: [{ id: 1 }, { id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: 1 }, { id: NaN }],
        i2h1t1: [{ id: 0 }, { id: 28 }],
        i3: [{ id: 28 }, { id: 1 }, { id: NaN }],
        i3h1t1: [{ id: 0 }],
      },
      a2: {
        i1: [{ id: 1 }, { id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: 1 }, { id: NaN }],
        i3: [{ id: 28 }, { id: NaN }],
      },
      a3: {
        i1: [{ id: 1 }, { id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: 1 }, { id: NaN }],
        i3: [{ id: 0 }, { id: 28 }, { id: NaN }],
      },
      a4: {
        i1: [{ id: 1 }, { id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: 1 }, { id: NaN }],
        i3: [{ id: 0 }, { id: 28 }, { id: NaN }],
      },
    },
    l2: {
      a1: {
        i1: [{ id: 26 }, { id: 3 }, { id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
        i2: [{ id: 3 }, { id: 26 }, { id: 2 }, { id: 28 }, { id: 16 }, { id: 18 }],
        i2h1t1: [{ id: 16 }, { id: 2 }],
        i2h1t0: [{ id: 2 }, { id: 16 }, { id: 18 }],
        i3: [{ id: 16 }, { id: 26 }, { id: 2 }, { id: 28 }, { id: 18 }],
        i3h1t1: [{ id: 0 }, { id: 16 }],
        i3h1t0: [{ id: 0 }, { id: 2 }, { id: 18 }, { id: 16 }],
      },
      a2: {
        i1: [{ id: 26 }, { id: 28 }, { id: 2 }, { id: 18 }, { id: 16 }],
        i2: [{ id: 3 }, { id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
        i3: [{ id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
        i2h1t0: [{ id: 16 }, { id: 2 }, { id: 28 }, { id: 18 }],
        i3h1t0: [{ id: 0 }, { id: 16 }, { id: 2 }, { id: 18 }],
      },
      a3: {
        i1: [{ id: 26 }, { id: 28 }, { id: 3 }, { id: 2 }],
        i2: [{ id: 26 }, { id: 28 }, { id: 2 }],
        i2h1t0: [{ id: 2 }, { id: 18 }, { id: 26 }, { id: 16 }, { id: 28 }],
        i3: [{ id: 0 }, { id: 2 }, { id: 28 }, { id: 16 }],
        i3h1t0: [{ id: 0 }, { id: 18 }, { id: 2 }, { id: 16 }, { id: 28 }],
      },
      a4: {
        i1: [{ id: 26 }, { id: 3 }, { id: 28 }, { id: 2 }],
        i2: [{ id: 26 }, { id: 28 }, { id: 2 }],
        i2h1t0: [{ id: 18 }, { id: 2 }, { id: 28 }, { id: 16 }],
        i3: [{ id: 0 }, { id: 2 }, { id: 28 }],
        i3h1t0: [{ id: 0 }, { id: 18 }, { id: 28 }, { id: 16 }],
      },
    },
    l3: {
      a1: {
        i1: [{ id: 4 }, { id: 5 }, { id: 6 }],
        i2: [{ id: 4 }, { id: 5 }, { id: 6 }],
        i2h1t1: [{ id: 6 }, { id: 20 }, { id: 34 }],
        i2h1t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 6 }],
        i3: [{ id: 6 }, { id: 20 }, { id: 5 }],
        i3h1t1: [{ id: 0 }, { id: 6 }, { id: 20 }, { id: 5 }, { id: 34 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 6 }, { id: 5 }, { id: 20 }],
      },
      a2: {
        i1: [{ id: 4 }, { id: 20 }, { id: 6 }, { id: 5 }],
        i2: [{ id: 5 }, { id: 4 }, { id: 20 }, { id: 6 }],
        i2h1t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }],
        i3: [{ id: 6 }, { id: 5 }, { id: 20 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 5 }, { id: 20 }],
      },
      a3: {
        i1: [{ id: 4 }, { id: 5 }, { id: 20 }, { id: 6 }],
        i2: [{ id: 5 }, { id: 4 }, { id: 6 }, { id: 20 }],
        i2h1t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }],
        i3: [{ id: 5 }, { id: 4 }, { id: 6 }],
        i3h1t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 6 }],
      },
      a4: {
        i1: [{ id: 4 }, { id: 5 }, { id: 20 }, { id: 6 }],
        i2: [{ id: 5 }, { id: 4 }, { id: 20 }, { id: 6 }],
        i2h1t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }, { id: 6 }],
        i3: [{ id: 0 }, { id: 6 }, { id: 5 }, { id: 4 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 5 }, { id: 20 }],
      },
    },
    l4: {
      a1: {
        i1: [{ id: 8 }, { id: 21 }],
        i2: [{ id: 21 }, { id: 8 }],
        i2h1t0: [{ id: 0 }, { id: 8 }, { id: 21 }],
        i2h1t1: [{ id: 21 }, { id: 7 }, { id: 8 }],
        i3: [{ id: 7 }, { id: 21 }, { id: 8 }],
        i3h1t0: [{ id: 0 }, { id: 21 }, { id: 8 }],
        i3h1t1: [{ id: 0 }, { id: 21 }, { id: 7 }],
      },
      a2: {
        i1: [{ id: 8 }, { id: 21 }, { id: 7 }],
        i2: [{ id: 8 }, { id: 21 }, { id: 7 }],
        i2h1t0: [{ id: 5 }, { id: 34 }, { id: 21 }],
        i3: [{ id: 0 }, { id: 21 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 21 }],
      },
      a3: {
        i1: [{ id: 8 }, { id: 21 }, { id: 5 }],
        i2: [{ id: 8 }, { id: 5 }],
        i2h1t0: [{ id: 21 }, { id: 34 }, { id: 5 }, { id: 8 }],
        i3: [{ id: 21 }, { id: 34 }, { id: 8 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 21 }, { id: 5 }, { id: 8 }],
      },
      a4: {
        i1: [{ id: 8 }, { id: 21 }, { id: 5 }],
        i2: [{ id: 8 }, { id: 5 }],
        i2h1t0: [{ id: 21 }, { id: 34 }, { id: 5 }, { id: 8 }],
        i3: [{ id: 21 }, { id: 34 }, { id: 8 }],
        i3h1t0: [{ id: 0 }, { id: 34 }, { id: 21 }, { id: 5 }, { id: 8 }],
      },
    },
    l5: {
      a1: {
        i1: [{ id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }],
        i2h1t1: [{ id: 0 }, { id: NaN }], // el 0 no va
        i3: [{ id: 28 }, { id: NaN }],
        i3h1t1: [{ id: 0 }, { id: NaN }],
      },
      a2: {
        i1: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }, { id: NaN }],
      },
      a3: {
        i1: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }, { id: NaN }],
      },
      a4: {
        i1: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }, { id: NaN }],
      },
    },
    l6: {
      a1: {
        i1: [{ id: 24 }, { id: 27 }, { id: 29 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 27 }, { id: 24 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2h1t1: [{ id: 9 }, { id: 10 }],
        i3: [{ id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3h1t1: [{ id: 0 }, { id: 9 }, { id: 10 }],
      },
      a2: {
        i1: [{ id: 24 }, { id: 27 }, { id: 29 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 10 }, { id: 29 }, { id: 24 }, { id: 27 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
      a3: {
        i1: [{ id: 24 }, { id: 29 }, { id: 27 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 27 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 9 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
      a4: {
        i1: [{ id: 24 }, { id: 29 }, { id: 27 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 27 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 9 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
    },
    l7: {
      a1: {
        i1: [{ id: 14 }, { id: 19 }, { id: 30 }, { id: 32 }],
        i2: [{ id: 19 }, { id: 14 }, { id: 30 }, { id: 32 }],
        i2h1t0: [{ id: 0 }, { id: 12 }, { id: 14 }, { id: 30 }, { id: NaN }],
        i2h1t1: [{ id: 12 }, { id: 30 }],
        i3: [{ id: 30 }, { id: 19 }, { id: 32 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
        i3h1t1: [{ id: 0 }, { id: 12 }],
      },
      a2: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: [{ id: 30 }, { id: 19 }, { id: 14 }, { id: 32 }],
        i2h1t0: [{ id: 12 }, { id: 32 }, { id: NaN }],
        i3: [{ id: 19 }, { id: 30 }, { id: 32 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
      },
      a3: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: [{ id: 30 }, { id: 14 }, { id: 19 }, { id: 32 }],
        i2h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
        i3: [{ id: 19 }, { id: 30 }, { id: 14 }, { id: 32 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
      },
      a4: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: [{ id: 30 }, { id: 14 }, { id: 19 }, { id: 32 }],
        i2h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
        i3: [{ id: 19 }, { id: 30 }, { id: 14 }, { id: 32 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: NaN }],
      },
    },
    l8: {
      a1: {
        i1: [{ id: 22 }, { id: 11 }, { id: 13 }],
        i2: [{ id: 22 }, { id: 11 }, { id: 13 }],
        i2h1t0: [{ id: 12 }, { id: 13 }],
        i2h1t1: [{ id: 22 }, { id: 11 }],
        i3: [{ id: 0 }, { id: 11 }, { id: 13 }, { id: 22 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: 13 }],
        i3h1t1: [{ id: 0 }, { id: 11 }, { id: 22 }],
      },
      a2: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: [{ id: 22 }, { id: 13 }, { id: 11 }],
        i2h1t0: [{ id: 12 }, { id: 22 }, { id: 13 }],
        i3: [{ id: 11 }, { id: 22 }, { id: 13 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: 22 }, { id: 13 }],
      },
      a3: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: [{ id: 22 }, { id: 13 }, { id: 11 }],
        i2h1t0: [{ id: 12 }, { id: 22 }, { id: 13 }],
        i3: [{ id: 11 }, { id: 22 }, { id: 13 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: 22 }, { id: 13 }],
      },
      a4: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: [{ id: 22 }, { id: 13 }, { id: 11 }],
        i2h1t0: [{ id: 12 }, { id: 22 }, { id: 13 }],
        i3: [{ id: 0 }, { id: 11 }, { id: 22 }, { id: 13 }],
        i3h1t0: [{ id: 0 }, { id: 12 }, { id: 22 }],
      },
    },
    l9: {
      a1: {
        i1: [{ id: 17 }, { id: 15 }],
        i2: [{ id: 15 }, { id: 17 }],
        i2h1t0: [{ id: 12 }, { id: 23 }],
        i2h1t1: [{ id: 17 }, { id: 23 }, { id: 12 }],
        i3: [{ id: 0 }, { id: 17 }, { id: 23 }],
        i3h1t0: [{ id: 0 }, { id: 17 }, { id: 23 }, { id: 12 }],
        i3h1t1: [{ id: 0 }, { id: 17 }, { id: 23 }],
      },
      a2: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: [{ id: 23 }, { id: 17 }, { id: 15 }],
        i2h1t0: [{ id: 12 }, { id: 23 }],
        i3: [{ id: 17 }, { id: 23 }, { id: 15 }],
        i3h1t0: [{ id: 0 }, { id: 23 }, { id: 12 }],
      },
      a3: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: [{ id: 23 }, { id: 15 }, { id: 17 }],
        i2h1t0: [{ id: 12 }, { id: 23 }],
        i3: [{ id: 17 }, { id: 23 }],
        i3h1t0: [{ id: 0 }, { id: 23 }, { id: 12 }],
      },
      a4: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2h1t0: [{ id: 12 }, { id: 23 }],
        i3: [{ id: 17 }, { id: 23 }],
        i3h1t0: [{ id: 0 }, { id: 23 }, { id: 17 }, { id: 12 }],
      },
    },
  },
  hinchazon: {
    l2: [{ id: 16 }],
    l3: [{ id: 6 }, { id: 20 }, { id: 5 }],
    l4: [{ id: 7 }, { id: 21 }],
    l6: [{ id: 12 }],
    l7: [{ id: 11 }, { id: 22 }],
    l8: [{ id: 17 }, { id: 23 }],
  },
  cansancio: {
    s0: {
      l2: [{ id: 2 }],
      l3: [{ id: 5 }],
      l5: [{ id: 10 }, { id: 9 }],
      l6: [{ id: 19 }],
    },
    s1: [{ id: NaN }],
  },
  tension: {
    l1: [{ id: 1 }],
    l2: [{ id: 2 }, { id: 3 }],
    l3: [{ id: 4 }],
    l4: [{ id: 8 }],
    l5: [{ id: 10 }],
    l6: [{ id: 14 }, { id: 13 }],
    l8: [{ id: 15 }],
  },
  relajacion: [{ id: 25 }],
};

export default recomendador;
