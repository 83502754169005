import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Box, Typography } from '@mui/material';
import NordicToggleButtonGrid from './NordicToggleButtonGrid';

const NordicWeekScale2: FC = () => {
  const week = useSelector((state: RootState) => state.nordicForm.weekCheck);
  const scale: any[] = [];
  if (week.cuello === true) {
    scale.push({ name: 'cuello', tag: 'Cuello' });
  }
  if (week.hombro === true) {
    scale.push({ name: 'hombro', tag: 'Hombro' });
  }
  if (week.brazoCodo === true) {
    scale.push({ name: 'brazoCodo', tag: 'Brazo/Codo' });
  }
  if (week.manoMuñeca === true) {
    scale.push({ name: 'manoMuñeca', tag: 'Mano/Muñeca' });
  }
  if (week.espaldaAlta === true) {
    scale.push({ name: 'espaldaAlta', tag: 'Espalda Alta' });
  }
  if (week.espaldaBaja === true) {
    scale.push({ name: 'espaldaBaja', tag: 'Espalda Baja' });
  }
  if (week.caderaPierna === true) {
    scale.push({ name: 'caderaPierna', tag: 'Cadera/Pierna' });
  }
  if (week.rodilla === true) {
    scale.push({ name: 'rodilla', tag: 'Rodilla' });
  }
  if (week.tobilloPie === true) {
    scale.push({ name: 'tobilloPie', tag: 'Tobillo/Pie' });
  }

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '500px',
          maxWidth: '92vw',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
        }}>
        <Typography variant="h5" sx={{ borderBottom: '1px solid grey' }}>
          ¿Cuánto fue la intensidad del dolor en los últimos 7 días?
        </Typography>
        <NordicToggleButtonGrid name="prueba" valueRange={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} columnVars={scale} />
      </Box>
    </Box>
  );
};

export default NordicWeekScale2;
