import React, { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const Consolto = () => {
  const { user } = useSelector((state: RootState) => state);
  useEffect(() => {
    window.addEventListener('consoltoEvent', (e: any) => {
      //waiting until consolto widget is ready
      if (e.detail.consoltoReady) {
        // Send client info
        const userDataEvent = new CustomEvent('consolto_h2w', {
          detail: {
            'et-client-info': true,
            'et-first-name': user.first_name, // modify the first name to the name that your platform captured.
            'et-last-name': user.last_name, // modify the last name to the name that your platform captured.
            'et-email': user.email, // modify the email to the email that your platform captured.
            'et-agent-id': 'AVAILABLE', // necessary only in the team-widget. In the single widget this line can be removed.
          },
        });
        window.dispatchEvent(userDataEvent);
      }
    });
  }, [user.first_name]);
  return <Fragment></Fragment>;
};

export default Consolto;
