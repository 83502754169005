import React, { FC, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Protocol } from '../../redux/types/protocolTypes';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import { Fade } from '@mui/material';
import { Card, CardMedia, CardActions, CardContent } from '@mui/material';
import { Button, Chip, Grid } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import stock_2 from './../../images/stock_2.png';
import stock_3 from './../../images/stock_3.png';

interface StateProps {
  specs: Protocol;
}

interface DispatchProps {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const ProtocolCard: FC<Props> = (props: Props) => {
  const onClick = () => {
    props.selectProtocolInteractor(props.specs.id);
  };

  return (
    <Fade in={true}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '10px',
          minWidth: '300px',
          maxWidth: '300px',
          width: '300px',
          borderRadius: '20px',
          paddingBottom: '12px',
          marginBottom: '25px',
          background: 'white',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          position: 'relative',
          top: '0',
          zIndex: '500',
        }}>
        <Fragment>
          <CardMedia
            onClick={onClick}
            sx={{
              padding: '0px',
              marginBottom: '0',
            }}
            component="img"
            height="174"
            width="100%"
            alt="default image"
            image={props.specs.imgUrl}
          />
          <img className="hover_slide_right" src={stock_3 ? stock_3 : stock_2} />
          <CardContent
            sx={{
              zIndex: '3',
              position: 'absolute',
              top: '120px',
              right: '0px',
              paddingY: '10px',
              background: 'transparent',
            }}>
            <Grid container direction="row" sx={{ marginY: '0px', paddingY: '0px' }}>
              <Grid item>
                <Chip size="small" color="info" label={props.specs.time} icon={<AccessTimeFilledIcon />} />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent
            sx={{
              marginTop: '-25px',
              paddingTop: '10px',
              paddingBottom: '50px',
              background: 'white',
              borderRadius: '20px 20px 0 0',
              zIndex: '2',
            }}>
            <h4 className="App-card-text">{props.specs.title}</h4>
          </CardContent>
          <CardActions sx={{ zIndex: '2', position: 'absolute', bottom: '10px', paddingY: '0px', width: '100%' }}>
            <Button
              sx={{ width: '100%', borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
              variant="contained"
              size="large"
              onClick={onClick}>
              Ver Mas
            </Button>
          </CardActions>
        </Fragment>
      </Card>
    </Fade>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(ProtocolCard);
