import React, { FC } from 'react';
import { bindActionCreators } from 'redux';
import { RootState } from '../../redux/store';
import { useSelector, connect } from 'react-redux';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import * as userInteractors from '../../redux/interactors/UserInteractors';
import userService from '../../services/UserServices';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import './Protocol.css';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Checkbox from '@mui/material/Checkbox';
import ConsoltoButton from './ConsoltoButton';

interface DispatchProps {
  saveProtocolInteractor: typeof userInteractors.saveProtocolInteractor;
  unsaveProtocolInteractor: typeof userInteractors.unsaveProtocolInteractor;
}

const Protocol: FC<DispatchProps> = (props: DispatchProps) => {
  const protocol = useSelector((state: RootState) => state.protocol.protocol);
  const filter = useSelector((state: RootState) => state.filter);
  const user = useSelector((state: RootState) => state.user);
  const protocolId = protocol ? protocol.id : NaN;
  const tags = filter.filters.filter((f) => f.protocolIds.includes(protocolId));
  const saved = user.saveds?.filter((saved) => saved.protocol_id === protocolId)[0];

  const saveProtocol = () => {
    userService.saveProtocol(protocol?.id, user?.id).then((response: any) => {
      if (+response.status === 201) {
        const new_saved = response?.data;
        props.saveProtocolInteractor({ value: new_saved });
      }
    });
  };
  const unsaveProtocol = () => {
    userService.unsaveProtocol(saved?.id).then((response: any) => {
      if (+response.status === 200) {
        props.unsaveProtocolInteractor({ value: saved?.id });
      }
    });
  };
  return (
    <Card sx={{ width: '100vw', height: '100%' }}>
      <CardHeader title={<h3 className="App-logo-text">{protocol?.title}</h3>} />
      <div className="scroll-box">
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: '10px', marginBottom: '80px', overflowY: 'auto', overflowX: 'hidden' }}>
          <Box sx={{ display: 'inline', witdh: '100vw', height: '60vh' }}>
            <iframe
              width="100%"
              height="100%"
              src={protocol?.videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; modestbranding; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'inline' }, width: '100vw', overflow: 'auto' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack direction="column" sx={{ minWidth: '50%', width: '100vw', maxWidth: '100vw', overflow: 'hidden' }}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  direction="row"
                  spacing={1}
                  display="inline-block">
                  <Checkbox
                    checked={saved ? true : false}
                    onClick={saved ? unsaveProtocol : saveProtocol}
                    icon={<BookmarkBorderIcon />}
                    checkedIcon={<BookmarkIcon />}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
                  <Chip color="info" sx={{ marginY: '5px' }} label={protocol?.time} icon={<AccessTimeFilledIcon />} />
                  <Chip color="info" sx={{ marginY: '5px' }} label={protocol?.bodyPart} icon={<AccessibilityIcon />} />
                  {tags.map((tag) => (
                    <Chip color="info" sx={{ marginY: '5px' }} key={`t-${tag.id}`} label={tag.name} />
                  ))}
                </Stack>
                <Stack direction="row" sx={{ margin: '10px' }}>
                  <ConsoltoButton />
                </Stack>
              </Stack>
              <Stack
                direction="column"
                sx={{ minWidth: '50%', width: '100vw', overflow: 'hidden', paddingBottom: '10vh' }}>
                {protocol?.steps?.map((step, index) => (
                  <Accordion key={`step${index}`}>
                    <AccordionSummary
                      expandIcon={<CloseIcon />}
                      aria-controls={`panel${index}a-content`}
                      id={`panel${index}a-header`}
                      sx={{ paddingY: 0, marginY: 0 }}>
                      <h1 className="App-logo-text">{step.subtitle}</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="h6" sx={{ margin: '0px', padding: '0px' }}>
                        <div className="md">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>{step.content}</ReactMarkdown>
                        </div>
                      </Typography>
                      <img src={step.imgUrl} width={'100%'} />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...userInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Protocol);
